import { setContentLoadedEventAsync } from "../../features/content/contentSlice";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import OnImagesLoaded from "react-on-images-loaded";

import amdLogo from "../../images/logos/amd-google-cloud-logo.svg";
import applicationLogo from "../../images/logos/EPYC_Explorer_Logo.svg";

import oceanWaves01 from "../../images/illustrations/ocean-waves-01.svg";
import oceanWaves02 from "../../images/illustrations/ocean-waves-02.svg";
import oceanWaves03 from "../../images/illustrations/ocean-waves-03.svg";

import terrain from "../../images/illustrations/terrain.svg";
import texture from "../../images/background/terrain-background-texture.png";
import compass from "../../images/illustrations/compass.svg";

import ZoomOutButton from "../inline/ZoomOutButton";
import ScreenOverlay from "./ScreenOverlay";
import Sidebar from "./Sidebar";
import Markers from "./Markers";
import Paths from "../inline/Paths";

import AnimatedShip from "./AnimatedShip";
import AnimatedDragon from "./AnimatedDragon";
import CloudsAboveMap from "./CloudsAboveMap";

export default function Map(props) {
  const application = useSelector((state) => state);
  const transformOrigin = application.map.transformOrigin;

  const [cloudsZoomedIn, setCloudsZoomedIn] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setCloudsZoomedIn(application.user.shouldShowUserInfoForm);
  }, [application.user]);

  useEffect(() => {
    if (application.content.contentLoadedEvent === true) {
      setContentLoaded(true);
    }
  }, [application.content.contentLoadedEvent]);

  return (
    <OnImagesLoaded onLoaded={() => dispatch(setContentLoadedEventAsync())}>
      <Sidebar />
      <Section
        className={`
          sidebar-is-visible-${application.map.sidebar.isOpen}
          content-loaded-${contentLoaded}
        `}
      >
        <Screen>
          <Ocean
            className={`
              map-is-zoomed-in-${application.map.isZoomedIn}
              clouds-zoomed-in-${cloudsZoomedIn}
            `}
            style={{
              transformOrigin: `${transformOrigin.x} ${transformOrigin.y}`,
            }}
          >
            <img src={oceanWaves01} alt="Illustration of ocean waves" />
            <OceanWavesTwo>
              <img src={oceanWaves02} alt="Illustration of ocean waves" />
            </OceanWavesTwo>
            <OceanWavesThree>
              <img src={oceanWaves03} alt="Illustration of ocean waves" />
            </OceanWavesThree>
            <Texture>
              <img src={texture} alt="Terrain texture" />
              <img src={terrain} alt="Illustration of terrain" />
            </Texture>
            <Logos>
              <img src={amdLogo} alt="AMD Google Cloud" />
              <img src={applicationLogo} alt="Epyc Explorer application logo" />
            </Logos>
            <AnimatedShip />
            <AnimatedDragon />
            <Compass>
              <img src={compass} alt="Compass illustration" />
            </Compass>
            <Paths />
            <Markers />
            <CloudsAboveMap cloudsZoomedIn={cloudsZoomedIn} />
          </Ocean>
        </Screen>
      </Section>
      <ZoomOutButton />
      <ScreenOverlay />
    </OnImagesLoaded>
  );
}

const Section = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: transform 0.5s ease-in-out, opacity 1s linear;
  transform: translateX(0) translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
  overflow: visible;
  opacity: 0;

  &.content-loaded-true {
    opacity: 1;
  }

  &.sidebar-is-visible-true {
    transform: translateX(-250px) translateZ(0);
  }
`;

const Screen = styled.div`
  position: relative;  
  max-height: 68vh;
  min-height: 420px;
  min-width: 840px;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 1%;
  width: 21.16%;
  margin-top: -23%;
  z-index: 20;

  img {
    max-width: 280px;
    transform: translate3d(0,0,0);
    backface-visibility: hidden;

    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

const Compass = styled.div`
  position: absolute;
  top: 50%;
  right: -2%;
  margin-top: -25%;
  width: 11.8%;
  transform-origin: center;
  transform: rotate(-5deg);
  animation: compass 8s ease-in-out infinite;
`;

const OceanWavesTwo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: center;
  animation: oceanWavesTwo 7s ease-in-out infinite;
  transition: opacity 2s linear 1s;
  opacity: 0;
`;

const OceanWavesThree = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-origin: center;
  animation: oceanWavesThree 7s ease-in-out infinite;
  transition: opacity 2s linear 1s;
  opacity: 0;
`;

const Ocean = styled.div`
  display: inline-block;
  position: relative;
  min-height: 100%;
  transform: scale(0.5) translate3d(0, 0, 10px);
  transition: transform 1.25s ease-in-out 0.25s,
  transform-origin 0.5s ease-in-out 1.25s;
  will-change: transform;
  backface-visibility: hidden;

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  > img {
    transform-origin: center;
    animation: oceanWavesOne 7s ease-in-out infinite;
    transition: opacity 2s linear 1s;
    opacity: 0;
  }

  &.clouds-zoomed-in-false {
    transform: scale(1);

    > img {
      opacity: 1;
    }

    ${OceanWavesTwo} {
      opacity: 1;
    }

    ${OceanWavesThree} {
      opacity: 1;
    }
  }

  &.map-is-zoomed-in-true {
    transform: scale(2) translateZ(0);
    transition: transform 1s ease-in-out 0.125s;
  }
`;

const Texture = styled.div`
  position: absolute;
  transform: translate3d(0, 0, 10px);
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 5.12%;
  width: 89.76%;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    display: inline-block;
    pointer-events: none;

    &:first-child {
      height: auto;
      width: 100%;
    }
  }
`;
