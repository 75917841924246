import { useSelector, useDispatch } from "react-redux";
import { openNotificationAsync } from "../../features/notification/notificationSlice";
import {
  zoomInOnTransformOriginAsync,
  openSidebarWithContentAsync,
} from "../../features/map/mapSlice";

import React from "react";
import styled from "styled-components";

import shack from "../../images/illustrations/shack.svg";
import shanty from "../../images/illustrations/shanty.svg";
import bridge from "../../images/illustrations/bridge.svg";
import farm from "../../images/illustrations/farm.svg";
import outro from "../../images/illustrations/outro.svg";
import markerHover from "../../images/background/marker-hover.svg";

import SupplementalOverviewMarker from "../inline/SupplementalOverviewMarker";
import SupplementalContentMarker from "../inline/SupplementalContentMarker";
import AnimatedCastleMarker from "./AnimatedCastleMarker";
import MapPin from "../inline/MapPin";
import Ribbon from "../../images/illustrations/inline/Ribbon";

const illustrations = {
  shack: shack,
  shanty: shanty,
  bridge: bridge,
  farm: farm,
  outro: outro,
};

export default function Markers(props) {
  const application = useSelector((state) => state);
  const courses = application.content.courses;

  const dispatch = useDispatch();

  const onMarkerClick = (thisCourse, incompletePrerequisites) => {
    if (application.map.isZoomedIn) return;

    if (incompletePrerequisites.length) {
      let forwardToCourse = incompletePrerequisites[0];

      if (incompletePrerequisites[0].isSupplementalContent) {
        const parentCourses = courses.filter((course) => course.localId === incompletePrerequisites[0].parentLocalId)
        if (parentCourses && parentCourses[0]) {
          forwardToCourse = parentCourses[0]
        }
      }

      dispatch(
        openNotificationAsync({
          type: `incompletePrerequisite`,
          heading: `${thisCourse.title} video is locked.`,
          paragraph: `Looks like you'll need to complete ${incompletePrerequisites[0].title} before you can watch ${thisCourse.title}. Navigate to the ${incompletePrerequisites[0].title} section and watch the main video, or click on the button below to continue.`,
          button: {
            label: `Go to ${incompletePrerequisites[0].title}`,
            actionType: `navigateToDifferentCourse`,
            course: forwardToCourse,
          },
        })
      );
      return;
    }

    dispatch(
      zoomInOnTransformOriginAsync({
        localId: thisCourse.localId,
        title: thisCourse.title,
        x: thisCourse.zoomOrigin.x,
        y: thisCourse.zoomOrigin.y,
      })
    );

    if (thisCourse.isIntroduction || thisCourse.isOutro) {
      setTimeout(() => {
        dispatch(openSidebarWithContentAsync(thisCourse));
      }, 1500);
    }
  };

  return (
    <React.Fragment>
      <MarkerPageOverlay
        className={`map-is-zoomed-in-${application.map.isZoomedIn}`}
      />
      {courses &&
        courses.map((course, index) => {
          if (course.isSupplementalContent) {
            return <div className="empty" key={`empty-${index}`} />;
          }

          const supplementalContent = courses.filter(
            (filtered) => filtered.parentTitle === course.title
          );

          const prerequisites = [];
          const incompletePrerequisites = [];

          if (application.content.courses.length) {
            const prerequisiteIds = course.prerequisiteLocalIds;

            if (prerequisiteIds && prerequisiteIds.length) {
              for (const prerequisiteId of prerequisiteIds) {
                const prerequisite = application.content.courses.filter(
                  (course) => course.localId === prerequisiteId
                );

                if (prerequisite && prerequisite.length) {
                  prerequisites.push(prerequisite[0]);
                }
              }
            }
          }

          if (prerequisites && prerequisites.length) {
            for (const prerequisite of prerequisites) {
              if (prerequisite.hasPassed === false) {
                incompletePrerequisites.push(prerequisite);
              }
            }
          }

          return (
            <Marker
              key={`map-marker-${index}`}
              className={`
                  map-is-zoomed-in-${application.map.isZoomedIn} 
                  map-is-zoomed-on-this-marker-${
                    application.map.zommedInOnSectionWithLocalId ===
                    course.localId
                  }
                  has-incomplete-prerequisites-${
                    incompletePrerequisites.length !== 0
                  }                  
                  is-intro-${course.isIntroduction}
              `}
              onClick={() => onMarkerClick(course, incompletePrerequisites)}
              style={{
                top: `${course.position.top}`,
                left: `${course.position.left}`,
                marginTop: `${course.position.marginTop}`,
                width: `${course.position.width}`,
              }}
            >
              <HoverBackground>
                <img src={markerHover} alt="Radial gradient" />
              </HoverBackground>
              {course.isOutro === true && (
                <AnimatedCastleMarker>
                  <img
                    src={illustrations[course.illustration]}
                    alt={course.title}
                  />
                </AnimatedCastleMarker>
              )}

              {course.isOutro !== true && (
                <img
                  src={illustrations[course.illustration]}
                  alt={course.title}
                />
              )}
              <RibbonContainer>
                <Ribbon />
                <span>{course.title}</span>
              </RibbonContainer>

              <MapPin
                course={course}
                key={`map-pin-${index}`}
                mapIsZommedIn={application.map.isZoomedIn}
                zommedInOnSectionWithLocalId={
                  application.map.zommedInOnSectionWithLocalId
                }                
                // hasIncompletePrerequisite={incompletePrerequisites.length !== 0}
              />              

              {supplementalContent && (
                <MarkerContent>
                  <SupplementalOverview>
                    {supplementalContent.map((supplemental, index) => (
                      <SupplementalOverviewMarker
                        course={course}
                        supplemental={supplemental}
                        key={`supplemental-overview-${index}`}
                        mapIsZommedIn={application.map.isZoomedIn}
                        zommedInOnSectionWithLocalId={
                          application.map.zommedInOnSectionWithLocalId
                        }
                        nonZeroIndex={index + 1}
                        totalMarkers={supplementalContent.length}
                        hasIncompletePrerequisites={incompletePrerequisites.length === 0}
                      />
                    ))}
                  </SupplementalOverview>
                  <SupplementalContent>
                    {supplementalContent.map((supplemental, index) => (
                      <SupplementalContentMarker
                        course={course}
                        supplemental={supplemental}
                        key={`supplemental-content-${index}`}
                        nonZeroIndex={index + 1}
                        totalMarkers={supplementalContent.length}
                      />
                    ))}
                  </SupplementalContent>
                </MarkerContent>
              )}
            </Marker>
          );
        })}
    </React.Fragment>
  );
}

const HoverBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  opacity: 0;
  transition: opacity 0.3s linear;
  pointer-events: none;

  > img {
    width: 100%;
  }
`;

const RibbonContainer = styled.div`
  position: relative;
  margin-top: -8px;
  z-index: 20;
  transition: opacity 0.6s linear;
  oapcity: 1;

  span {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translate3d(-50%, 0px, 10px);
    font-size: 0.95rem;
    -webkit-font-smoothing: antialiased;
    text-transform: uppercase;
  }

  > svg {
    transform: translate3d(0px, 0px, 10px);

    .change-on-hover {
      transition: fill 0.3s linear;
    }
  }
`;

const MarkerPageOverlay = styled.div`
  position: fixed;
  top: -50vh;
  left: -50vw;
  right: 0;
  bottom: 0;
  width: 200vw;
  height: 200vh;
  opacity: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.25), transparent);
  transition: opacity 0.8s linear;
  pointer-events: none;
  z-index: 10;

  &.map-is-zoomed-in-true {
    opacity: 1;
  }
`;

const Marker = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.6s linear;

  > img {
    position: relative;
    transition: opacity 0.6s linear;
    transform: translate3d(0px, 0px, 0px);
    opacity: 0.7;
    z-index: 20;
  }

  &:hover, &.has-incomplete-prerequisites-false {
    > img {
      opacity: 1;
    }

    ${HoverBackground} {
      opacity: 1;
    }

    ${RibbonContainer} {
      > svg {
        .change-on-hover {
          fill: var(--gold-color);
        }
      }
    }
  }

  &.map-is-zoomed-in-true {
    pointer-events: none;
  }

  &.map-is-zoomed-in-true.map-is-zoomed-on-this-marker-true {
    z-index: 20;

    > img {
      opacity: 1;
    }

    ${HoverBackground} {
      opacity: 1;
    }

    ${RibbonContainer} {
      > svg {
        .change-on-hover {
          fill: var(--gold-color);
        }
      }
    }
  }

  &.map-is-zoomed-in-true.map-is-zoomed-on-this-marker-false {
    opacity: 0.75;
  }
`;

const MarkerContent = styled.div`
  z-index: 20;
`;

const SupplementalContent = styled.div`
  width: 200%;
  height: 20px;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  top: -40px;
  left: -50%;
`;

const SupplementalOverview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
`;
