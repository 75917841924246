import { useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function VideoPlayer() {
  const application = useSelector((state) => state);
  
  const [containerClassNames, setContainerClassNames] = useState();
  const [contentClassNames, setContentClassNames] = useState();
  const [videoData, setVideoData] = useState();

  useEffect(() => {
    if (application.video.isOpening === true) {
      setContainerClassNames(`container-is-visible-true`);
    }

    if (application.video.isClosing === true) {
      setContainerClassNames(`container-is-visible-false`);
    }

    if (application.video.isOpen === true) {
      setContentClassNames(`content-is-visible-true`);      
    }

    if (application.video.isClosed === true) {
      setContentClassNames(`content-is-visible-false`);      
    }
  }, [application.video]);

  useEffect(() => {
    if (!application.auth.accessToken) return;
    
    if (!application.video.courseId) {
      setVideoData(undefined)
    }

    setVideoData({
      courseId: application.video.courseId,
      accessToken: application.auth.accessToken,
    });
  }, [application.auth.accessToken, application.video.courseId]);

  return (
    <Container className={`${containerClassNames}`}>
      <FluidicPlayer className={`${contentClassNames}`}>
        {videoData && videoData.courseId && (
          <iframe
            allowFullScreen={true}
            title={`adobe-fluidic-player-${videoData.courseId}`}
            src={`https://captivateprime.adobe.com/app/player?lo_id=${videoData.courseId}&access_token=${videoData.accessToken}`}
          />
        )}
      </FluidicPlayer>
    </Container>
  );
}

const Container = styled.div`
  z-index: 30;

  &.container-is-visible-true {
    transform: translateY(0);
  }
`;

const FluidicPlayer = styled.div`  
  iframe {    
    width: 100%;    
    border: none;
    aspect-ratio: 16 / 11;   
    background: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    padding: 15px 1.5rem 0;
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
`;
