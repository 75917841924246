import React from "react";

export default function PathOne() {
  return (
    <svg
      width="227"
      height="163"
      viewBox="0 0 227 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127 2C127 2 137.5 87.9138 98.0001 92C69.0001 95 -27 50.5 9.00003 122.5C34.5 168 75.3856 115.15 122 142C184.5 178 205.878 154.359 225.5 152"
        stroke="#4B4345"
        strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
