import React from "react";
import styled, { keyframes } from "styled-components";

import castleAnimationFrame01 from "../../images/animation-frames/castle/castle-frame-1.svg";
import castleAnimationFrame02 from "../../images/animation-frames/castle/castle-frame-2.svg";
import castleAnimationFrame03 from "../../images/animation-frames/castle/castle-frame-3.svg";
import castleAnimationFrame04 from "../../images/animation-frames/castle/castle-frame-4.svg";
import castleAnimationFrame05 from "../../images/animation-frames/castle/castle-frame-5.svg";

export default function AnimatedCastle(props) {
  return (
    <React.Fragment>
      <Castle>
        {props.background === true && (
          <CastleBackground
            className={`is-notification-${props.isNotification}`}
          />
        )}
        <CastleAnimation
          className={`is-notification-${props.isNotification}`}
        />
      </Castle>

      <LoadAssets>
        <img src={castleAnimationFrame01} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame02} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame03} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame04} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame05} alt={"Hidden castle illustration"} />
      </LoadAssets>
    </React.Fragment>
  );
}

const castleAnimation = keyframes`
  0% {
    background-image: url("${castleAnimationFrame01}");            
  }

  20% {
    background-image: url("${castleAnimationFrame02}");
  }

  40% {
    background-image: url("${castleAnimationFrame03}");
  }

  60% {
    background-image: url("${castleAnimationFrame04}");
  }

  80% {
    background-image: url("${castleAnimationFrame05}");
  }

  100% {
    background-image: url("${castleAnimationFrame01}");            
  }
`;

const Castle = styled.div`
  position: relative;
`;

const CastleBackground = styled.div`
  position: absolute;
  width: 260px;
  height: 260px;
  background: var(--gold-color);
  opacity: 0.25;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  &.is-notification-true {
    width: 180px;
    height: 180px;
  }
`;

const CastleAnimation = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: ${castleAnimation};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: infinite;
  background-position: center;
  z-index: 20;
  position: relative;
  transition: opacity 0.3s linear;
  width: 100%;
  height: 260px;

  &.is-notification-true {
    height: 180px;
  }
`;

const LoadAssets = styled.div`
  display: none;
`;
