import React, { useEffect, useState } from "react";
import { openNotificationAsync } from "../../features/notification/notificationSlice";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  patchUserFieldsAsync,
  requestUserWithIdAsync,
  setShouldShowUserInfoForm,
  setShouldShowFormSuccess,
} from "../../features/user/userSlice";

import SelectInputField from "../inline/SelectInputField";
import TextInputField from "../inline/TextInputField";

import Loading from "../../screens/Loading";
import styled, { css } from "styled-components";

import Ship from "../../images/illustrations/inline/Ship";
import NotificationLockIcon from "../../images/icons/inline/NotificationLockIcon";

import RightArrowIcon from "../../images/icons/inline/RightArrowIcon";
import BackArrowIcon from "../../images/icons/inline/BackArrowIcon";

import paperTexture from "../../images/background/paper-texture.png";
import TagManager from 'react-gtm-module'

export default function UserInfoForm(props) {
  const application = useSelector((state) => state);
  const dispatch = useDispatch();

  const cookies = useCookies(["skipUserInfoForm"]);
  const setCookie = cookies[1];

  const [renderFields, setRenderFields] = useState([]);
  const [steppedRenderFields, setSteppedRenderFields] = useState([]);

  const [profileButtonClicked, setProfileButtonClicked] = useState(false);
  const [completedFormSteps, setCompletedFormSteps] = useState([]);

  const [currentVisibleFormStep, setCurrentVisibleFormStep] = useState(0);
  const [previousVisibleFormSteps, setPreviousVisibleFormSteps] = useState([]);

  const [whatsThisIsVisible, setWhatsThisIsVisible] = useState(false);
  const [formIsVisible, setFormIsVisible] = useState();

  const adobeFields = application.user.adobeFields;
  const expectedAdobeFields = application.user.expectedAdobeFields;
  const shouldShowUserInfoForm = application.user.shouldShowUserInfoForm;
  const shouldShowFormSuccess = application.user.shouldShowFormSuccess;

  /**
   * Format and store raw field data from Adobe LMS to local state
   */
  useEffect(() => {
    if (shouldShowUserInfoForm) {
      const fieldObjects = [];

      for (const expectedField of expectedAdobeFields) {
        let fieldValue = "";

        let matchingAdobeField = adobeFields.filter(
          (adobeField) => adobeField.nameInAdobe === expectedField.nameInAdobe
        );

        // If the value the field value is existing in Adobe, pass value
        // to the local state tracking form entries
        if (matchingAdobeField && matchingAdobeField[0]) {
          fieldValue = matchingAdobeField[0].value;

          // If the field value is not set in Adobe, check to see if we can
          // use user auth data to pre-populate the form with certain fields
        } else if (expectedField.nameInAdobe === "First Name") {
          const name = application.user.name.split(" ");

          if (name && name[0]) {
            fieldValue = name[0];
          }
        } else if (expectedField.nameInAdobe === "Last Name") {
          const name = application.user.name.split(" ");

          if (name && name[1]) {
            fieldValue = name[1];
          }
        } else if (expectedField.nameInAdobe === "Business Email") {
          fieldValue = application.user.email;
        }

        fieldObjects.push({
          ...expectedField,
          value: fieldValue,
        });
      }

      setRenderFields(fieldObjects);
    }
  }, [
    adobeFields,
    expectedAdobeFields,
    shouldShowUserInfoForm,
    application.user.email,
    application.user.name,
  ]);

  /**
   * Organize input fields into multiple steps
   */
  useEffect(() => {
    if (renderFields.length) {
      const steppedFields = [];

      // Get interger value representing number of steps in form
      let numberOfSteps = 3;

      // Seed formatted fields array with an array for each number of steps
      for (let i = 0; i < numberOfSteps; i++) {
        steppedFields[i] = [];
      }

      // Build array for fields formatted for rendering
      let currentStep = 1;
      renderFields.forEach((item, index) => {
        steppedFields[currentStep - 1].push(item);

        if (
          item.nameInAdobe === "Business Phone Number" ||
          item.nameInAdobe === "Location / Region"
        ) {
          currentStep = currentStep + 1;
        }
      });

      setSteppedRenderFields(steppedFields);
    }
  }, [renderFields]);

  /**
   * Track completed form steps when steppedFields state is updated
   */
  useEffect(() => {
    if (steppedRenderFields.length) {
      const completedSteps = [];

      steppedRenderFields.forEach((fieldsInThisStep, stepIndex) => {
        let numCompletedFields = 0;

        fieldsInThisStep.forEach((fieldInThisStep) => {
          if (fieldInThisStep.value || fieldInThisStep.required === false) {
            numCompletedFields = numCompletedFields + 1;
          }
        });

        if (numCompletedFields === fieldsInThisStep.length) {
          if (!completedSteps.includes(`form-step-${stepIndex}`)) {
            completedSteps.push(`form-step-${stepIndex}`);
          }
        }

        setCompletedFormSteps(completedSteps);
      });
    }
  }, [steppedRenderFields]);

  /**
   * Open the user info form when clicking user profile button
   */
  useEffect(() => {
    if (profileButtonClicked === true) return;

    const cookie = application.user.hasSkipUserInfoFormCookie;
    const allAdobeFieldsFilled = application.user.allAdobeFieldsFilled;
    const shouldShowUserInfoForm = application.user.shouldShowUserInfoForm;

    if (typeof allAdobeFieldsFilled === "undefined") return;

    if (!cookie && allAdobeFieldsFilled === false) {
      if (typeof shouldShowUserInfoForm === "undefined") {
        dispatch(setShouldShowUserInfoForm(true));
      }
    }

    if (cookie && cookie === true) {
      if (typeof shouldShowUserInfoForm === "undefined") {
        dispatch(setShouldShowUserInfoForm(false));
      }
    }

    if (allAdobeFieldsFilled === true) {
      if (typeof shouldShowUserInfoForm === "undefined") {
        dispatch(setShouldShowUserInfoForm(false));
      }
    }
  }, [application.user, profileButtonClicked, dispatch]);

  /**
   * Open user info form when change on redux store user data change
   */
  useEffect(() => {
    if (
      application.user.shouldShowUserInfoForm === true &&
      renderFields.length
    ) {
      setTimeout(() => {
        setFormIsVisible(true);
      }, 500);
    } else if (
      application.user.shouldShowUserInfoForm === false &&
      renderFields.length
    ) {
      setFormIsVisible(false);
    }
  }, [application.user, renderFields]);

  useEffect(() => {
    if (shouldShowFormSuccess === true) {
      setTimeout(() => {
        dispatch(
          openNotificationAsync({
            type: `playerProfileFormSuccess`,
            heading: `Welcome to EPYC Explorer!`,
            paragraph: `Thank you for registering! This training program will cover AMD technology on Google Cloud along with our joint sales programs and customer wins. This program is estimated to take 3-4 hours to complete and you may save your progress and come back at any time.<br/><br/>We will utilize the information you’ve provided to deliver your prizes to you if you are daring enough to complete the platform!<br/><br/>Feel free to view your completed profile here. If you have any questions or need to make changes, please contact us at <a href="mailto:gcp@amd.com">gcp@amd.com</a> or refer to our privacy policy for more information.`,
            button: {
              label: `Start`,
              actionType: `closeFormSuccess`,
            },
          })
        );
      }, 1000);
    }
  }, [dispatch, shouldShowFormSuccess]);

  const onSkipButtonClick = () => {
    const now = new Date();
    const expires = new Date();
    expires.setDate(now.getDate() + 1);

    setProfileButtonClicked(true);
    setCookie("skipUserInfoForm", true, {
      path: "/",
      expires: expires,
    });

    setFormIsVisible(false);

    TagManager.dataLayer({
      dataLayer: {
        event: 'registration',
        registration_action: `Close player profile`                       
      }
    })

    setTimeout(() => {
      dispatch(setShouldShowUserInfoForm(false));
    }, 1000);
  };

  const onContinueButtonClick = (event) => {
    event.preventDefault();

    const fieldsInCurrentStep = steppedRenderFields[currentVisibleFormStep];          
    
    for( let i = 0; i < fieldsInCurrentStep.length; i++ ) {
      if (fieldsInCurrentStep[i].isPhoneNumber) {
        const isValid = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(fieldsInCurrentStep[i].value )                             
        
        const validatedRenderFields = [...steppedRenderFields];
        if (isValid === true) {                              
          validatedRenderFields[currentVisibleFormStep][i].validationMessage = undefined                            
        } else {
          validatedRenderFields[currentVisibleFormStep][i].validationMessage = "Please enter valid phone number."
        } 
           
        setSteppedRenderFields(validatedRenderFields);

        if (isValid === false) return; 
      }
    }

    setCurrentVisibleFormStep(currentVisibleFormStep + 1);

    if (!previousVisibleFormSteps.length) {
      const previousSteps = [currentVisibleFormStep];
      setPreviousVisibleFormSteps(previousSteps);
    } else {
      const previousSteps = [...previousVisibleFormSteps];
      if (!previousSteps.includes(currentVisibleFormStep)) {
        previousSteps.push(currentVisibleFormStep);
      }

      setPreviousVisibleFormSteps(previousSteps);
    }    

    TagManager.dataLayer({
      dataLayer: {
        event: 'registration',
        registration_action: `Complete player profile step ${currentVisibleFormStep + 1}`                       
      }
    })
  };

  const onCloseButtonClick = (event) => {
    event.preventDefault();
    dispatch(setShouldShowUserInfoForm(false));    
  };

  const onBackButtonClick = (event) => {
    event.preventDefault();
    setCurrentVisibleFormStep(currentVisibleFormStep - 1);

    if (previousVisibleFormSteps.length) {
      const indexOfCurrentStep = previousVisibleFormSteps.indexOf(
        currentVisibleFormStep
      );

      if (indexOfCurrentStep !== -1) {
        const previousVisibleSteps = [...previousVisibleFormSteps];
        previousVisibleSteps.splice(indexOfCurrentStep, 1);

        setPreviousVisibleFormSteps(previousVisibleSteps);
      }
    }
  };

  const onFieldChange = (event) => {
    const updatedRenderFields = [];

    for (const renderField of renderFields) {
      if (renderField.localName === event.target.id) {
        updatedRenderFields.push({
          ...renderField,
          value: event.target.value,
        });
      } else {
        updatedRenderFields.push(renderField);
      }
    }

    setRenderFields(updatedRenderFields);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    const fields = {};
    renderFields.forEach((field) => {
      if ( field.nameInAdobe === "Business Phone Number" ) {
        const value = field.value      
        fields[field.nameInAdobe] = value.replace(/[^\w\s]/gi, '');
      } else {
        fields[field.nameInAdobe] = field.value;
      }                        
    });    

    dispatch(
      patchUserFieldsAsync({
        userId: application.user.id,
        fields: fields,
      })
    )
      .then(() => {
        dispatch(
          requestUserWithIdAsync({
            userId: application.user.id,
            accessToken: application.auth.accessToken,
          })
        );
      })
      .then(() => {
        dispatch(setShouldShowFormSuccess(true));
        TagManager.dataLayer({
          dataLayer: {
            event: 'registration',
            registration_action: `Submit user profile form`                       
          }
        })
      });
  };

  if (steppedRenderFields.length) {
    return (
      <Form
        onSubmit={(event) => onFormSubmit(event)}
        className={`form-is-visible-${formIsVisible} should-show-form-success-${shouldShowFormSuccess}`}
      >
        {steppedRenderFields.map((renderFieldsInStep, stepIndex) => (
          <FormStep
            key={`form-step-${stepIndex}`}
            className={`
              is-previously-visible-step-${previousVisibleFormSteps.includes(
                stepIndex
              )}
              is-current-step-${
                stepIndex === currentVisibleFormStep
              }               
              whats-this-is-visible-${whatsThisIsVisible}`}
          >
            <Icon>
              {application.user.allAdobeFieldsFilled ? (
                <NotificationLockIcon />
              ) : (
                <Ship />
              )}
            </Icon>
            <FormFields>
              <FormHeader>
                {stepIndex === 0 ? (
                  <span className="empty" />
                ) : (
                  <BackArrowContainer onClick={onBackButtonClick}>
                    <BackArrowIcon />
                  </BackArrowContainer>
                )}

                <Heading>Welcome to EPYC Explorer!</Heading>
              </FormHeader>
              <FormSubHeader>
                {stepIndex + 1 === 3 ? (
                  <Paragraph>Mailing Address for Prizes</Paragraph>
                ) : (
                  <Paragraph>
                    Please sign up below to start your adventure
                  </Paragraph>
                )}

                <SubHeading>
                  Step {stepIndex + 1} of {steppedRenderFields.length}
                </SubHeading>
              </FormSubHeader>
              {renderFieldsInStep.map((renderFieldInStep, index) => {
                let showLockIcon = true;
                let isDisabled =
                  renderFieldInStep.nameInAdobe === "Business Email";

                if (application.user.allAdobeFieldsFilled) {
                  isDisabled = true;
                  showLockIcon = false;
                }                

                if (renderFieldInStep.options) {
                  return (
                    <SelectInputField
                      data={renderFieldInStep}
                      onChange={onFieldChange}
                      isDisabled={isDisabled}
                      showLockIcon={showLockIcon}
                      key={index}
                    />
                  );
                } else {
                  return (
                    <TextInputField
                      isDisabled={isDisabled}
                      showLockIcon={showLockIcon}
                      onChange={onFieldChange}
                      data={renderFieldInStep}
                      key={index}
                    />
                  );
                }
              })}
              <ButtonsContainer>
                <WhatsThisButton>
                  {!application.user.allAdobeFieldsFilled && (
                    <span onClick={() => setWhatsThisIsVisible(true)}>
                      What's this?
                    </span>
                  )}
                  {application.user.allAdobeFieldsFilled && <span>&nbsp;</span>}
                </WhatsThisButton>

                {stepIndex + 1 !== steppedRenderFields.length && (
                  <ContinueButton
                    className={`is-disabled-${!completedFormSteps.includes(
                      `form-step-${stepIndex}`
                    )}`}
                  >
                    <button onClick={(event) => onContinueButtonClick(event)}>
                      <span>Next Step</span>
                      <RightArrowIcon />
                    </button>
                  </ContinueButton>
                )}

                {stepIndex + 1 === steppedRenderFields.length &&
                  !application.user.allAdobeFieldsFilled && (
                    <Submitbutton
                      className={`is-disabled-${!completedFormSteps.includes(
                        `form-step-${stepIndex}`
                      )}`}
                    >
                      <button>
                        <span>Submit</span>
                        <RightArrowIcon />
                      </button>
                    </Submitbutton>
                  )}

                {stepIndex + 1 === steppedRenderFields.length &&
                  application.user.allAdobeFieldsFilled && (
                    <CloseButton className="is-disabled-false">
                      <button onClick={(event) => onCloseButtonClick(event)}>
                        <span>Return to Map</span>
                      </button>
                    </CloseButton>
                  )}

                <SkipButton>
                  {!application.user.allAdobeFieldsFilled && (
                    <span onClick={() => onSkipButtonClick()}>
                      I'll do this later
                    </span>
                  )}
                  {application.user.allAdobeFieldsFilled && (
                    <span onClick={() => onSkipButtonClick()}>Close</span>
                  )}
                </SkipButton>
              </ButtonsContainer>
            </FormFields>
          </FormStep>
        ))}
        <WhatsThis className={`is-visible-${whatsThisIsVisible}`}>
          <TextContent>
            <Heading>How will you be using my info?</Heading>
            <Paragraph>
              When you complete each section, you'll get a prize. There's three
              total sections, so you can win up to three prizes! We'll need your
              name and address to ship the prizes once you complete all three
              sections of the course. If you'd prefer to opt-out of receiving
              prizes, you can skip this section by clicking "I'll do this
              later".
            </Paragraph>
          </TextContent>
          <ButtonsContainer>
            <ContinueButton className="is-disabled-false">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setWhatsThisIsVisible(false);
                }}
              >
                <span>Back to Player Profile</span>
              </button>
            </ContinueButton>

            <SkipButton>
              {!application.user.allAdobeFieldsFilled && (
                <span onClick={onSkipButtonClick}>I'll do this later</span>
              )}
              {application.user.allAdobeFieldsFilled && (
                <span onClick={() => onSkipButtonClick()}>Close</span>
              )}
            </SkipButton>
          </ButtonsContainer>
        </WhatsThis>
      </Form>
    );
  }

  return <Loading text={`Preparing homing pigeons...`} />;
}

const Icon = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  background-image: url(${paperTexture});
  background-size: 300px;
  background-position: center;
  border-radius: 50%;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    margin-top: -15px;

    path {
      fill: var(--dark-blue-color);
    }
  }
`;

const WhatsThisButton = styled.div`
  text-align: right;
  text-decoration: underline;
  margin-bottom: 1rem;

  span {
    font-size: 0.75rem;
    cursor: pointer;
    font-family: var(--secondary-font);
    color: var(--dark-brown-color);
  }
`;

const Form = styled.form`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: var(--content-min-height);
  overflow: scroll;

  &.form-is-visible-true {
    opacity: 1;
    pointer-events: all;
  }

  &.should-show-form-success-true {
    > div {
      top: 150%;
    }
  }
`;

const FormHeader = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 0;
  position: relative;
  width: 100%;

  h2 {
    margin-top: 0;
  }

  svg {
    width: 18px;
    height: auto;
    cursor: pointer;
    margin-top: 4px;

    path {
      stroke: var(--dark-brown-color);
    }
  }
`;

const FormSubHeader = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;

  p {
    margin-top: 0.25rem;
  }

  h4 {
    margin: 0.25rem 0;
    font-family: var(--secondary-font);
    font-size: 0.9rem;
  }
`;

const BackArrowContainer = styled.div``;

const Heading = styled.h2`
  color: var(--dark-brown-color);  
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 21px;
  margin-top: 8px;
`;

const SubHeading = styled.h4`
  font-weight: 500;
`;

const Paragraph = styled.p`
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  a {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
  }
`;

const formStep = css`
  width: 50%;
  max-width: 580px;
  background-image: url(${paperTexture});
  background-size: 470px;
  background-position: center;
  border-radius: 30px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: left 1s ease, top 0.4s ease-in-out;
  top: 50%;
  left: 200vw;  
`;

const FormStep = styled.div`
  ${formStep}

  &.is-previously-visible-step-true {
    left: -200vw;
  }

  &.is-current-step-true {
    left: 50%;

    &.whats-this-is-visible-true {
      left: -200vw;
    }
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px 55px 45px;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const SkipButton = styled.div`
  margin-top: 0.5rem;
  text-align: center;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-decoration: underline;
    font-family: var(--secondary-font);
    color: var(--dark-brown-color);
    cursor: pointer;
  }
`;

const button = css`
  display: inline-block;
  border: solid 1px var(--dark-brown-color);
  margin-top: 8px;
  align-self: baseline;
  margin-left: auto;
  margin-right: auto;

  button {
    color: var(--dark-brown-color);
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    padding: 13px 50px;
    line-height: 21px;
  }

  svg {
    margin-left: 5px;
    transform: translateY(0) translateX(0px);
    transition: transform 0.3s ease-in-out;
    backface-visibility: hidden;
  }

  &:hover {
    svg {
      transform: translateY(0) translateX(4px);
    }
  }

  &.is-disabled-true {
    opacity: 0.75;
    pointer-events: none;
  }

  &.is-disabled-false {
    background-color: var(--gold-color);
  }
`;

const CloseButton = styled.div`
  ${button}
`;

const Submitbutton = styled.div`
  ${button}
`;

const ContinueButton = styled.div`
  ${button}
`;

const WhatsThis = styled.div`
  width: 50%;
  max-width: 500px;
  background-image: url(${paperTexture});
  background-size: 470px;
  background-position: center;
  border-radius: 10px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: left 1s ease;
  top: 50%;
  left: 200vw;
  text-align: center;
  padding-bottom: 40px;

  &.is-visible-true {
    left: 50%;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 60px 0 60px;
`;
