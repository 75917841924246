import { zoomOutAsync } from "../../features/map/mapSlice";
import { setShouldShowUserInfoForm } from "../../features/user/userSlice";
import { openNotificationAsync } from "../../features/notification/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import React, { useState, useEffect } from "react";

import UserProfileIcon from "../../images/icons/inline/UserProfileIcon";
import CheckCircleWhiteOnBrown from "../../images/icons/inline/CheckCircleWhiteOnBrown";

import paper from "../../images/background/treasure-tracker-paper.png";
import simplifiedChestClosed from "../../images/illustrations/simplified-chest-closed.svg";
import simplifiedChestOpen from "../../images/illustrations/simplified-chest-open.svg";
import TagManager from "react-gtm-module";

import { application as data, contact } from "../../data/data";

function calculatePercentageComplete(courses, parentLocalId, setSection) {
  const requiredCourses = [];

  const parentCourse = courses.filter(
    (course) => course.localId === parentLocalId
  );

  if (parentCourse && parentCourse[0]) {
    requiredCourses.push(parentCourse[0]);

    const completedCourses = [];

    const supplementalContent = courses.filter(
      (course) => course.parentLocalId === parentLocalId
    );

    if (parentCourse[0].hasPassed === true) {
      completedCourses.push(parentCourse[0]);
    }

    for (const course of supplementalContent) {
      if (course.hasPassed) {
        completedCourses.push(course);
      }

      requiredCourses.push(course);
    }

    const percentComplete =
      (completedCourses.length / requiredCourses.length) * 100;

    const nextCourseLocalId = parseFloat(parentCourse[0].localId) + 1;
    const nextCourse = courses.filter(
      (course) => parseFloat(course.localId) === nextCourseLocalId
    );

    if (nextCourse && nextCourse[0]) {
      setSection({
        percentComplete: percentComplete,
        requiredCourses: requiredCourses,
        parentCourse: parentCourse[0],
        nextCourse: nextCourse[0],
      });
    }
  }
}

export default function TreasureTracker(props) {
  const application = useSelector((state) => state);

  const dispatch = useDispatch();

  const [sectionOne, setSectionOne] = useState();
  const [sectionTwo, setSectionTwo] = useState();
  const [sectionThree, setSectionThree] = useState();
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    setContentLoaded(!application.user.shouldShowUserInfoForm);
  }, [application.user]);

  useEffect(() => {
    calculatePercentageComplete(
      application.content.courses,
      "1.0",
      setSectionOne
    );
    calculatePercentageComplete(
      application.content.courses,
      "2.0",
      setSectionTwo
    );
    calculatePercentageComplete(
      application.content.courses,
      "3.0",
      setSectionThree
    );
  }, [
    setSectionOne,
    setSectionTwo,
    setSectionThree,
    application.content.courses,
  ]);

  const onChestClick = (section) => {
    if (section.percentComplete !== 100) {
      const paragraph = `It looks like you still have to complete a course or two before you unlock this treasure chest. Complete all courses in the ${section.parentCourse.title} section to unlock this chest.`;

      dispatch(
        openNotificationAsync({
          type: `incompleteTresureChest`,
          heading: `Treasure chest is locked!`,
          paragraph: paragraph,
        })
      );

      return;
    }

    dispatch(
      openNotificationAsync({
        type: `completeTreasureChest`,
        heading: section.parentCourse.treasureChest.title,
        paragraph: section.parentCourse.treasureChest.paragraph,
        treasureChest: {
          color: section.parentCourse.treasureChest.color,
          image: section.parentCourse.treasureChest.image,
        },
        button: {
          label: `Next Step`,
          actionType: `navigateToDifferentCourse`,
          course: section.nextCourse,
        },
      })
    );

    TagManager.dataLayer({
      dataLayer: {
        event: 'treasure_tracker',
        treasure_tracker_action: `Completed treasure chest ${section.parentCourse.treasureChest.color} icon click`                       
      }
    })
  };

  const onCompletedProfileIconClick = () => {
    dispatch(
      openNotificationAsync({
        type: `profileComplete`,
      })
    );
  };

  const onUserProfileClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'registration',
        registration_action: `User profile icon click`                       
      }
    })

    if (application.map.isZoomedIn) {
      dispatch(zoomOutAsync());

      setTimeout(() => {
        dispatch(setShouldShowUserInfoForm(true));
      }, 1500);
    } else {
      dispatch(setShouldShowUserInfoForm(true));
    }
  };

  const onResourcesClick = (event) => {
    event.preventDefault(event);

    dispatch(
      openNotificationAsync({
        type: `resources`,
        heading: `General Resources`,
        paragraph: `Please utilize the following links for reference and reach out to <a href="mailto:gcp@amd.com">gcp@amd.com</a> if you need any specific information.<br/><br/>
        <a href="https://www.amdgcpartners.com/p/1" target="_blank" rel="noreferrer">Partner Resources Page</a><br/>
        <a href="https://cloud.google.com/amd" target="_blank" rel="noreferrer">cloud.google.com/amd</a><br/>
        <a href="/5-Reasons-Why-AMD-Google-Cloud.pdf" target="_blank" rel="noreferrer">5 Reasons Why AMD and Google Cloud</a><br/>
        <a href="/privacy-policy">Privacy Policy</a> |         
        <a href="/EPYC-Explorer-Terms-and-Conditions-2024.pdf" target="_blank" rel="noreferrer">Terms and Conditions</a>`,
      })      
    );

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_title: `Resources`                       
      }
    })
  };  
  
  const onLogoutClick = (event) => {
    event.preventDefault();

    document.cookie = "refreshToken=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

    var iframe = document.createElement("iframe");
    iframe.setAttribute("src", data.endpoints.logout);
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.id = "logout-iframe";
    document.body.appendChild(iframe);

    var iframe2 = document.createElement("iframe");
    iframe2.setAttribute("src", data.endpoints.adobeLogout);
    iframe2.style.width = "0px";
    iframe2.style.height = "0px";
    iframe2.id = "logout-iframe2";
    document.body.appendChild(iframe2);

    setTimeout(() => {
      document.location.replace(data.endpoints.home);
    }, 1000);

    return;
  };

  return (
    <Section
      className={`content-loaded-${contentLoaded} map-is-zoomed-in-${application.map.isZoomedIn}`}
    >
      <MaxWidth>
        <Title>
          <h3>Treasure Tracker</h3>
        </Title>
        <Chests>
          <Divider>
            {sectionOne && (
              <SectionTitle>{sectionOne.parentCourse.title}</SectionTitle>
            )}
            {sectionOne && (
              <FilledDivider
                style={{ width: `${sectionOne.percentComplete}%` }}
              />
            )}
          </Divider>
          <BronzeChest
            className={`section-complete-${
              sectionOne && sectionOne.percentComplete === 100
                ? "true"
                : "false"
            }`}
            onClick={() => onChestClick(sectionOne)}
          >
            {sectionOne && sectionOne.percentComplete === 100 && (
              <CheckCircleWhiteOnBrown />
            )}
          </BronzeChest>
          <Divider>
            {sectionTwo && (
              <SectionTitle>{sectionTwo.parentCourse.title}</SectionTitle>
            )}
            {sectionTwo && (
              <FilledDivider
                style={{ width: `${sectionTwo.percentComplete}%` }}
              />
            )}
          </Divider>
          <SilverChest
            className={`section-complete-${
              sectionTwo && sectionTwo.percentComplete === 100
                ? "true"
                : "false"
            }`}
            onClick={() => onChestClick(sectionTwo)}
          >
            {sectionTwo && sectionTwo.percentComplete === 100 && (
              <CheckCircleWhiteOnBrown />
            )}
          </SilverChest>
          <Divider>
            {sectionThree && (
              <SectionTitle>{sectionThree.parentCourse.title}</SectionTitle>
            )}
            {sectionThree && (
              <FilledDivider
                style={{ width: `${sectionThree.percentComplete}%` }}
              />
            )}
          </Divider>
          <GoldChest
            className={`section-complete-${
              sectionThree && sectionThree.percentComplete === 100
                ? "true"
                : "false"
            }`}
            onClick={() => onChestClick(sectionThree)}
          >
            {sectionThree && sectionThree.percentComplete === 100 && (
              <CheckCircleWhiteOnBrown />
            )}
          </GoldChest>
        </Chests>
        <Legal>
          <LegalLink className="no-pipe">
            <a href={`mailto:${contact.email}`}>Contact</a>
          </LegalLink>         
          <LegalLink>
            <a href="/resources" onClick={(event) => onResourcesClick(event)}>
              Resources
            </a>
          </LegalLink>         
          <LegalLink>
            <a onClick={(event) => onLogoutClick(event)} href="/logout">
              Logout
            </a>
          </LegalLink>
        </Legal>
        {application.user.allAdobeFieldsFilled ? (
          <CompletedUserProfile onClick={onCompletedProfileIconClick}>
            <CompletedUserProfileIcon>
              <CheckCircleWhiteOnBrown />
            </CompletedUserProfileIcon>
            <UserProfileIcon />
          </CompletedUserProfile>
        ) : (
          <UserProfile onClick={() => onUserProfileClick()}>
            <UserProfileIcon />
          </UserProfile>
        )}
      </MaxWidth>
    </Section>
  );
}

const CompletedUserProfile = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 14px;
`;

const CompletedUserProfileIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -10px;

  svg {
    width: 14px;
  }
`;

const Section = styled.section`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-image: url(${paper});
  background-size: cover;
  padding: 75px 60px 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.75s ease-in-out 0.7s, opacity 0.75s linear 0.7s;
  filter: drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.25));

  &.content-loaded-true {
    transform: translateY(0);
    opacity: 1;
  }

  &.map-is-zoomed-in-true {
    transform: translateY(100%);
    transition: transform 0.75s ease-in-out 0.25s, opacity 0.75s linear 0.5s;
  }
`;

const MaxWidth = styled.div`
  width: 100%;
  max-width: var(--content-max-width);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  flex: 2;
  margin-top: -6px;
  margin-right: 4px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--bronze-color);
  }
`;

const Chests = styled.div`
  display: flex;
  flex: 5.5;  
  margin-right: 18px;
`;

const Chest = css`
  border-radius: 50%;
  background-size: 72%;
  border: solid 3px var(--white-color);
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  transform: translateZ(0px);
  backface-visibility: hidden;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.125));

  &.section-complete-true {
    background-image: url(${simplifiedChestOpen});
    border-color: var(--bronze-color);

    > svg {
      circle {
        stroke-width: 1;
        stroke: var(--bronze-color);
      }
    }
  }

  &.section-complete-false {
    background-image: url(${simplifiedChestClosed});
  }

  svg {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 22px;

    circle {
      stroke: var(--white-color);
    }
  }
`;

const Divider = styled.div`
  position: relative;
  flex: 1;
  background-image: linear-gradient(
    90deg,
    var(--dark-brown-color) 50%,
    transparent 50%
  );
  background-position: center;
  background-repeat: repeat-x;
  background-size: 10px 3px;
  margin: 0 0 0 0;
`;

const FilledDivider = styled.div`
  width: 0;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--bronze-color);
  transform: translateY(-50%);
  transition: width 0.3s ease-in-out;
`;

const BronzeChest = styled.div`
  ${Chest}
  background-color: rgba(176, 141, 80, 0.4);
`;

const SilverChest = styled.div`
  ${Chest}
  background-color: rgba(155, 150, 134, 0.4);
`;

const GoldChest = styled.div`
  ${Chest}
  background-color: rgba(229, 183, 101, 0.4);
`;

const SectionTitle = styled.h4`
  color: var(--dark-brown-color);
  text-transform: uppercase;
  margin: 0;
  transform: translateY(12px);
  font-size: 14px;
`;

const Legal = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const LegalLink = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: background: var(--dark-brown-color);
    text-decoration: none;  
    margin-right: 10px;
    letter-spacing: 0.25px;
  }

  span {
    cursor: pointer;
    margin-right: 14px;
  }

  &:not(.no-pipe):before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 30px;
    background: var(--dark-brown-color);
    margin-right: 10px;
    opacity: 0.6;
  }

  &:last-child {    
    &:after {
      content: "";
      display: inline-block;
      width: 2px;
      height: 30px;
      background: var(--dark-brown-color);
      margin-left: 0;
      opacity: 0.6;
    }
  }
`;

const UserProfile = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
