import React from "react";

export default function LockIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="17.5"
        transform="rotate(-90 18 18)"
        fill="#F3EBE3"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5003 20.4997C19.5003 19.6753 18.8244 18.9994 18 18.9994C17.1756 18.9994 16.4997 19.6753 16.4997 20.4997C16.4997 21.3254 17.1756 22 18 22C18.8244 22 19.5003 21.3254 19.5003 20.4997ZM15.7497 13.7495C15.7497 12.5047 16.7553 11.5003 18.0001 11.5003C19.245 11.5003 20.2506 12.5047 20.2506 13.7495V15.2499H15.7497V13.7495ZM21.7495 15.2498V13.7495C21.7495 11.6803 20.0705 10 18 10C15.9295 10 14.2505 11.6803 14.2505 13.7495V15.2498H13.5003C12.6746 15.2498 12 15.9245 12 16.7502V24.2505C12 25.0749 12.6746 25.7495 13.5003 25.7495H22.4997C23.3254 25.7495 24 25.0749 24 24.2505V16.7502C24 15.9245 23.3254 15.2498 22.4997 15.2498H21.7495Z"
        fill="#4F453C"
      />
    </svg>
  );
}
