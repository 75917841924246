import { setHasSkipUserInfoFormCookie } from "./features/user/userSlice";
import { closeVideoPlayerAsync } from "./features/video/videoSlice";
import {
  closeSidebarClearContentAsync,
  zoomOutAsync,
} from "./features/map/mapSlice";
import {
  requestUpdatedContentAsync,
  clearHasCompletedCourseAsync,
} from "./features/content/contentSlice";
import { openNotificationAsync } from "./features/notification/notificationSlice";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { application as data } from "./data/data";

import Notification from "./components/block/Notification";
import AuthRedirect from "./screens/AuthRedirect";
import Layout from "./components/block/Layout";
import Home from "./screens/Home";
import Loading from "./screens/Loading";
import NotFound from "./screens/NotFound";

import Game from "./screens/Game";
import PrivacyPolicy from "./components/block/PrivacyPolicy";
import TagManager from "react-gtm-module";

function App() {
  const application = useSelector((state) => state);

  const [cookies] = useCookies(["refreshToken"]);
  const [videoIsClosing, setVideoIsClosing] = useState(false);
  const [eventListenerAdded, setEventListenerAdded] = useState();
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState();

  const dispatch = useDispatch();

  const onVideoCloseMessage = useCallback(() => {
    if (videoIsClosing === true) return;

    setVideoIsClosing(true);
    dispatch(closeVideoPlayerAsync());

    setTimeout(() => {
      dispatch(closeSidebarClearContentAsync());
      setVideoIsClosing(false);
    }, 500);
  }, [dispatch, videoIsClosing]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-57HL5TR5",
      events: {
        view_course: "view_course"
      }
    }

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (videoIsClosing === true) {
      const accessToken = application.auth.accessToken;
      const enrollmentId = application.video.lastViewedEnrollmentId;

      setTimeout(() => {
        dispatch(
          requestUpdatedContentAsync({
            accessToken: accessToken,
            enrollmentId: enrollmentId,
            courses: application.content.courses,
          })
        );
      }, 1000);
    }
  }, [
    dispatch,
    videoIsClosing,
    application.auth.accessToken,
    application.video.lastViewedEnrollmentId,
    application.content.courses,
  ]);

  useEffect(() => {
    if (eventListenerAdded === true) return;

    window.addEventListener("message", function closePlayer(event) {
      if (event.data === "status:close") {
        onVideoCloseMessage();
        setEventListenerAdded(true);
      }
    });
  }, [eventListenerAdded, onVideoCloseMessage]);

  useEffect(() => {
    if (cookies.refreshToken) {
      setShouldRedirectToLogin(false);
    }

    if (typeof cookies.refreshToken === "undefined") {
      setShouldRedirectToLogin(true);
    }
  }, [cookies.refreshToken, setShouldRedirectToLogin]);

  useEffect(() => {
    if (application.content.hasCompletedSection === true) {
      const completedSection = application.content.completedSection;

      if (completedSection.isIntroduction) {
        dispatch(zoomOutAsync());

        setTimeout(() => {
          dispatch(clearHasCompletedCourseAsync());
        }, 1200);

        return;
      }

      if (completedSection.isOutro) {
        const incompletePrerequisites = application.content.courses.filter(
          (course) => course.isSupplementalContent && !course.hasPassed
        );

        if (incompletePrerequisites.length) {
          dispatch(
            openNotificationAsync({
              type: `completeOutroWithRemainingContent`,
              heading: `You made it!`,
              subHeading:
                "But not so fast, you still have some buried treasure to find.",
              paragraph: `Thanks for completing the Epyc Explorer quest. Make sure to watch all supplemental content to unlock more treasure. We'll let you know when your treasure is shipped.`,
              button: {
                label: `Continue`,
                actionType: `closeNotification`,
              },
            })
          );
          return;
        }

        if (incompletePrerequisites.length === 0) {
          dispatch(
            openNotificationAsync({
              type: `completeOutroNoRemainingContent`,
              heading: `You've made it!`,
            })
          );

          TagManager.dataLayer({
            dataLayer: {
              event: 'treasure_tracker',
              treasure_tracker_action: `Show finish completion popup`                       
            }
          })

          return;
        }
      }

      const nextCourseLocalId = parseFloat(completedSection.localId) + 1;
      const nextCourse = application.content.courses.filter(
        (course) => parseFloat(course.localId) === nextCourseLocalId
      );

      dispatch(
        openNotificationAsync({
          type: `completeTreasureChest`,
          heading: completedSection.treasureChest.title,
          paragraph: completedSection.treasureChest.paragraph,
          treasureChest: {
            color: completedSection.treasureChest.color,
            image: completedSection.treasureChest.image,
          },
          button: {
            label: `Next Step`,
            actionType: `navigateToDifferentCourse`,
            course: nextCourse[0],
          },
        })        
      );

      TagManager.dataLayer({
        dataLayer: {
          event: 'treasure_tracker',
          treasure_tracker_action: `Show ${completedSection.treasureChest.color} completion popup`                       
        }
      })
    }
  }, [
    application.content.hasCompletedSection,
    application.content.completedSection,
    application.content.courses,
    dispatch,
  ]);

  useEffect(() => {
    if (typeof cookies.skipUserInfoForm === "undefined") {
      dispatch(setHasSkipUserInfoFormCookie(false));
    }

    if (cookies.skipUserInfoForm === "true") {
      dispatch(setHasSkipUserInfoFormCookie(true));
    }
  }, [cookies, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {typeof shouldRedirectToLogin === "undefined" && (
            <React.Fragment>
              <Route
                path={data.routes.home}
                element={<Loading text="Loading..." />}
              />
              <Route path="*" element={<NotFound />} />
            </React.Fragment>
          )}

          {shouldRedirectToLogin === true && (
            <React.Fragment>
              <Route path={data.routes.home} element={<Home />} />
              <Route path={data.routes.auth} element={<AuthRedirect />} />
              <Route
                path={data.routes.privacyPolicy}
                element={<PrivacyPolicy />}
              />
              <Route path="*" element={<NotFound />} />
            </React.Fragment>
          )}

          {shouldRedirectToLogin === false && (
            <React.Fragment>
              <Route path={data.routes.home} element={<Game />} />
              <Route
                path={data.routes.privacyPolicy}
                element={<PrivacyPolicy />}
              />
              <Route path="*" element={<NotFound />} />
            </React.Fragment>
          )}
        </Route>
      </Routes>
      <Notification />
    </BrowserRouter>
  );
}

export default App;
