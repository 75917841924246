import React from "react";
import styled from "styled-components";
import expandIcon from "../../images/icons/expand-more.svg";
import LockIcon from "../../images/icons/inline/LockIcon";

export default function SelectInputField(props) {
  const { value } = props.data;

  return (
    <FormField>
      <label htmlFor={props.data.localName}>
        {props.data.nameInAdobe}
        {props.data.required === false && (
          <>&nbsp;(Optional)</>
        )}
        {props.isDisabled && props.showLockIcon && <LockIcon />}
      </label>
      <select
        id={props.data.localName}
        onChange={(event) => props.onChange(event)}
        disabled={props.isDisabled}
        value={value}
        className={`is-value-empty-${value ? `false` : `true`}`}
      >
        <option value="">Please choose an option</option>
        {props.data.options &&
          props.data.options.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
      </select>
    </FormField>
  );
}

const FormField = styled.div`
  margin-bottom: 1rem;
  font-weight: 400;
  max-width: 100%;
  width: 100%;

  select {
    width: 100%;
    border: solid 1px var(--dark-brown-color);
    font-family: var(--secondary-font);
    padding: 9px 9px 8px 9px;
    border-radius: 3px;
    max-width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
    appearance: none;
    background-image: url(${expandIcon});
    background-position: calc(100% - 10px) calc(50% + 2px);
    background-repeat: no-repeat;
    color: var(--dark-brown-color);

    &.is-value-empty-true {
      color: #4f453c7a;
    }

    &:disabled {
      cursor: initial;
    }

    option {
      color: #000000;
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 0.9rem;
    font-family: var(--secondary-font);

    svg {
      margin-left: 5px;
      height: 14px;
    }
  }
`;
