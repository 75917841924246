import React from "react";

export default function Ribbon() {
  return (
    <svg
      width="163"
      height="38"
      viewBox="0 0 163 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M162 10.5H141.5V24.5L126 37H162L155 24.5L162 10.5Z"
        fill="#D6CAB7"
        stroke="#4F453C"
        strokeLinejoin="round"
        className="change-on-hover"
      />
      <path d="M125.5 25V37L141.5 25H125.5Z" fill="#4F453C" />
      <path
        d="M1 10.5H21.5V24.5L37 37H1L8 24.5L1 10.5Z"
        fill="#D6CAB7"
        stroke="#4F453C"
        strokeLinejoin="round"
        className="change-on-hover"
      />
      <path d="M37.5 25V37L21.5 25H37.5Z" fill="#4F453C" />
      <rect
        x="21.5"
        y="0.5"
        width="120"
        height="24"
        rx="0.5"
        fill="#D6CAB7"
        stroke="#4F453C"
        className="change-on-hover"
      />
    </svg>
  );
}
