import React from "react";
import styled, { keyframes } from "styled-components";

import chestAnimationFrame01 from "../../images/animation-frames/chest/treasure-frame-1.svg";
import chestAnimationFrame02 from "../../images/animation-frames/chest/treasure-frame-2.svg";
import chestAnimationFrame03 from "../../images/animation-frames/chest/treasure-frame-3.svg";
import chestAnimationFrame04 from "../../images/animation-frames/chest/treasure-frame-4.svg";

export default function AnimatedTreasureChest() {
  return (
    <React.Fragment>
      <AnimatedChest />
      <LoadAssets>
        <img src={chestAnimationFrame01} alt={"Hidden chest illustration"} />
        <img src={chestAnimationFrame02} alt={"Hidden chest illustration"} />
        <img src={chestAnimationFrame03} alt={"Hidden chest illustration"} />
        <img src={chestAnimationFrame04} alt={"Hidden chest illustration"} />
      </LoadAssets>
    </React.Fragment>
  );
}

const LoadAssets = styled.div`
  display: none;
`;

export const chestOpenAnimation = keyframes`
  0% {
      background-image: url("${chestAnimationFrame01}");               
  }

  5% {
      background-image: url("${chestAnimationFrame01}");               
  }

  10% {
      background-image: url("${chestAnimationFrame02}");                
  }

  15% {
      background-image: url("${chestAnimationFrame03}");                
  }

  20% {
      background-image: url("${chestAnimationFrame04}");        
  }

  25% {
    background-image: url("${chestAnimationFrame04}");        
  }

  30% {
      background-image: url("${chestAnimationFrame03}");               
  }

  35% {
      background-image: url("${chestAnimationFrame02}");                
  }

  40% {
      background-image: url("${chestAnimationFrame01}");                
  }

  45% {
    background-image: url("${chestAnimationFrame01}");               
  }

  50% {
      background-image: url("${chestAnimationFrame02}");                
  }

  55% {
      background-image: url("${chestAnimationFrame03}");                
  }

  60% {
      background-image: url("${chestAnimationFrame04}");        
  }

  65% {
    background-image: url("${chestAnimationFrame04}");        
  }

  70% {
      background-image: url("${chestAnimationFrame03}");               
  }

  75% {
      background-image: url("${chestAnimationFrame02}");                
  }

  80% {
      background-image: url("${chestAnimationFrame01}");                
  }

  85% {
    background-image: url("${chestAnimationFrame01}");               
  }

  90% {
      background-image: url("${chestAnimationFrame02}");                
  }

  95% {
      background-image: url("${chestAnimationFrame03}");                
  }

  100% {
    background-image: url("${chestAnimationFrame04}");        
  }
`;

const chestCloseAnimation = keyframes`
  0% {
      background-image: url("${chestAnimationFrame04}");               
  }

  33% {
      background-image: url("${chestAnimationFrame03}");                
  }

  66% {
      background-image: url("${chestAnimationFrame02}");                
  }

  100% {
      background-image: url("${chestAnimationFrame01}");        
  }
`;

export const AnimatedChest = styled.div`
  background-repeat: no-repeat;
  position: absolute;
  top: -5%;
  left: -5%;
  right: 0;
  bottom: 0;
  height: 110%;
  background-position: center;
  background-size: 80% 130%;
  animation-name: ${chestCloseAnimation};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-fill-mode: forwards;
`;
