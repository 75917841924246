import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ScreenOverlay() {
  const application = useSelector((state) => state);

  const [overlayIsVisible, setOverlayIsVisible] = useState(false);  

  useEffect(() => {
    if (
      (application.notification.isOpen &&
        application.notification.message.type !== "playerProfileFormSuccess") ||
      application.map.sidebar.isOpen
    ) {
      setOverlayIsVisible(true);
      return;
    }

    setOverlayIsVisible(false);
  }, [
    application.notification.isOpen,
    application.map.sidebar.isOpen,
    application.notification.message.type,
  ]);

  return <Overlay className={`overlay-is-visible-${overlayIsVisible}`} />;
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  background-position: center;
  background-size: cover;
  transform: translateX(100%);
  transition: transform 0.2s linear 0.4s, opacity 0.4s linear 0s;
  opacity: 0;
  z-index: 20;

  &.overlay-is-visible-true {
    transition: transform 0.2s linear 0s, opacity 0.4s linear 0.2s;
    transform: translateX(0%);
    opacity: 1;
  }
`;
