import React from "react";
import styled, { keyframes } from "styled-components";

import castleAnimationFrame01 from "../../images/animation-frames/castle/castle-frame-1.svg";
import castleAnimationFrame02 from "../../images/animation-frames/castle/castle-frame-2.svg";
import castleAnimationFrame03 from "../../images/animation-frames/castle/castle-frame-3.svg";
import castleAnimationFrame04 from "../../images/animation-frames/castle/castle-frame-4.svg";
import castleAnimationFrame05 from "../../images/animation-frames/castle/castle-frame-5.svg";

export default function AnimatedCastleMarker(props) {
  return (
    <React.Fragment>
        <Castle>
            {props.children}
        </Castle>
      <LoadAssets>
        <img src={castleAnimationFrame01} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame02} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame03} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame04} alt={"Hidden castle illustration"} />
        <img src={castleAnimationFrame05} alt={"Hidden castle illustration"} />
      </LoadAssets>
    </React.Fragment>
  );
}

const LoadAssets = styled.div`
  display: none;
`;

const castleAnimation = keyframes`
  0% {
    background-image: url("${castleAnimationFrame01}");            
  }

  20% {
    background-image: url("${castleAnimationFrame02}");
  }

  40% {
    background-image: url("${castleAnimationFrame03}");
  }

  60% {
    background-image: url("${castleAnimationFrame04}");
  }

  80% {
    background-image: url("${castleAnimationFrame05}");
  }

  100% {
    background-image: url("${castleAnimationFrame01}");            
  }
`;


const Castle = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: ${castleAnimation};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: infinite;
  background-position: bottom center;
  opacity: 0.7;
  z-index: 20;
  position: relative;
  transition: opacity 0.3s linear;  

  img {
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, 0, 0px);
  }

  &:hover {
    opacity: 1;
  }
`;
