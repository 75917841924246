import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LockIcon from "../../images/icons/inline/LockIcon";

export default function TextInputField(props) {
  const internationalMessage = "N/A if not applicable";
  const [showInternationalMessage, setShowInternationalMessage] =
    useState(false);

  useEffect(() => {
    if (props.data.nameInAdobe) {
      switch (props.data.nameInAdobe) {
        case "City":
          return setShowInternationalMessage(true);
        case "State":
          return setShowInternationalMessage(true);
        default:
          return;
      }
    }
  }, [props.data.nameInAdobe]);

  return (
    <FormField className={`is-disabled-${props.isDisabled} layout-${props.data.layoutClassName}`}>
      <label htmlFor={props.data.localName}>
        {props.data.fieldLabel ? props.data.fieldLabel : props.data.nameInAdobe}
        {props.data.required === false && (
          <SupplementalLabel>&nbsp;(Optional)</SupplementalLabel>
        )}
        {showInternationalMessage && (
          <SupplementalLabel>
            &nbsp;{`(${internationalMessage})`}
          </SupplementalLabel>
        )}
        {props.isDisabled && props.showLockIcon && <LockIcon />}
      </label>      

      <input
        type="text"
        disabled={props.isDisabled}
        id={props.data.localName}
        value={props.data.value}
        onChange={(event) => props.onChange(event)}
      />
      
      {props.data.validationMessage && (
        <ValidationMessage>{props.data.validationMessage}</ValidationMessage>
      )}
    </FormField>
  );
}

const ValidationMessage = styled.small`
  font-family: var(--secondary-font);
  display: inline-block;
  font-size: 0.75rem;
  margin-top: 6px;
  color: red;
`

const SupplementalLabel = styled.span`
  opacity: 0.75;
  margin-bottom: 6px;
  font-family: var(--secondary-font);
`;

const FormField = styled.div`
  margin-bottom: 1rem;
  font-weight: 400;
  max-width: 100%;
  width: 100%;

  &.layout-half {    
    width: calc(50% - 0.5rem);
  }

  &.is-disabled-true {
    label {
      svg {
        margin-left: 5px;
        height: 14px;
      }
    }

    input {
      opacity: 0.75;
      pointer-events: none;
    }
  }

  input {
    width: 100%;
    border: solid 1px var(--dark-brown-color);
    font-family: var(--secondary-font);
    padding: 9px 9px 8px 9px;
    border-radius: 3px;
    max-width: 100%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.3);
    color: var(--dark-brown-color);
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 0.9rem;
    font-family: var(--secondary-font);
  }
`;
