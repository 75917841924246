import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import styled, { css } from "styled-components";

import PathOne from "../../images/illustrations/inline/PathOne";
import PathTwo from "../../images/illustrations/inline/PathTwo";
import PathThree from "../../images/illustrations/inline/PathThree";
import PathFour from "../../images/illustrations/inline/PathFour";

export default function Paths() {
  const application = useSelector((state) => state);

  const [introSectionPassed, setIntroSectionPassed] = useState(false);
  const [sectionOnePassed, setSectionOnePassed] = useState(false);
  const [sectionTwoPassed, setSectionTwoPassed] = useState(false);
  const [sectionThreePassed, setSectionThreePassed] = useState(false);

  const [delayAnimation, setDelayAnimation] = useState(true);

  useEffect(() => {
    if (application.content.contentLoadedEvent === true) {
      const content = application.content;

      const completedCourses = {
        introSection: {
          hasPassed: content.introSectionPassed,
          updateFunction: setIntroSectionPassed,
        },
        sectionOne: {
          hasPassed: content.sectionOnePassed,
          updateFunction: setSectionOnePassed,
        },
        sectionTwo: {
          hasPassed: content.sectionTwoPassed,
          updateFunction: setSectionTwoPassed,
        },
        sectionThree: {
          hasPassed: content.sectionThreePassed,
          updateFunction: setSectionThreePassed,
        },
      };

      for (const course in completedCourses) {
        if (completedCourses[course].hasPassed) {
          completedCourses[course].updateFunction(true);
        }
      }
    }
  }, [application.content]);

  useEffect(() => {
    if (application.map.isZoomingOut === true) {
      const content = application.content;

      setDelayAnimation(false);

      switch (application.map.zommedInOnSectionWithLocalId) {
        case "0.0":
          setIntroSectionPassed(content.introSectionPassed);
          break;
        case "1.0":
          setSectionOnePassed(content.sectionOnePassed);
          break;
        case "2.0":
          setSectionTwoPassed(content.sectionTwoPassed);
          break;
        case "3.0":
          setSectionThreePassed(content.sectionThreePassed);
          break;
        default:
          return;
      }
    }
  }, [
    application.map.isZoomingOut,
    application.content,
    application.map.zommedInOnSectionWithLocalId,
    setIntroSectionPassed,
    setSectionOnePassed,
    setSectionTwoPassed,
    setSectionThreePassed
  ]);

  return (
    <Container className={`map-is-zoomed-in-${application.map.isZoomedIn}`}>
      <InlinePathOne>
        <PathOne />
        {introSectionPassed && (
          <AnimatedPath className={delayAnimation ? `delay-sm` : `delay-xs`}>
            <PathOne />
          </AnimatedPath>
        )}
      </InlinePathOne>
      <InlinePathTwo>
        <PathTwo />
        {sectionOnePassed && (
          <AnimatedPath className={delayAnimation ? `delay-md` : `delay-xs`}>
            <PathTwo />
          </AnimatedPath>
        )}
      </InlinePathTwo>
      <InlinePathThree>
        <PathThree />
        {sectionTwoPassed && (
          <AnimatedPath className={delayAnimation ? `delay-lg` : `delay-xs`}>
            <PathThree />
          </AnimatedPath>
        )}
      </InlinePathThree>
      <InlinePathFour>
        <PathFour />
        {sectionThreePassed && (
          <AnimatedPath className={delayAnimation ? `delay-xl` : `delay-xs`}>
            <PathFour />
          </AnimatedPath>
        )}
      </InlinePathFour>
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.6s linear;

  &.map-is-zoomed-in-true {
    opacity: 0.25;
  }
`;

const InlinePath = css`
  position: absolute;    
  top: 50%;

  svg {
    height: auto;
    width: 100%;
  }
`;

const InlinePathOne = styled.div`
  left: 11.9%;
  margin-top: 1.75%;
  width: 16%;

  ${InlinePath};
`;

const InlinePathTwo = styled.div`
  left: 33.7%;
  margin-top: -1.2%;
  width: 15.25%;

  ${InlinePath};
`;

const InlinePathThree = styled.div`
  left: 66.1%;
  margin-top: -7.25%;
  width: 7.45%;

  ${InlinePath};
`;

const InlinePathFour = styled.div`
  left: 83.5%;
  margin-top: -6.6%;
  width: 4.6%;

  ${InlinePath};
`;

const AnimatedPath = styled.div`
  transform: translate3d(0, 0, 10px);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  svg {
    path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: fillPath 4s linear forwards;
      stroke-width: 3;
      stroke-opacity: 1;
      stroke: var(--bronze-color);
    }
  }

  &.delay-xs {
    svg {
      path {
        animation-delay: 0.25s;
      }
    }
  }

  &.delay-sm {
    svg {
      path {
        animation-delay: 0s;
      }
    }
  }

  &.delay-md {
    svg {
      path {
        animation-delay: 2s;
      }
    }
  }

  &.delay-lg {
    svg {
      path {
        animation-delay: 4s;
      }
    }
  }

  &.delay-xl {
    svg {
      path {
        animation-delay: 5s;
      }
    }
  }
`;
