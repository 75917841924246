import { application, content } from "../../data/data";
import axios from "axios";

export async function fetchUpdatedContent(value) {
  try {
    const response = await axios({
      url: `${application.endpoints.functions}/get-enrollment`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        accessToken: value.accessToken,
        enrollmentId: value.enrollmentId,
      },
    });

    if (!response || !response.data) {
      return new Promise((resolve) =>
        resolve({ error: true, message: "no data returned from Adobe" })
      );
    }

    return new Promise((resolve) =>
      resolve({ ...response.data.data, courses: value.courses })
    );
  } catch (error) {
    return new Promise((resolve) => resolve(error.data));
  }
}

export async function fetchMergedContent(accessToken) {
  const updatedContent = {
    introSectionPassed: false,
    sectionOnePassed: false,
    sectionTwoPassed: false,
    sectionThreePassed: false,    
    courses: undefined
  }

  const mergedVideoContent = [];  

  try {
    const response = await axios({
      url: `${application.endpoints.functions}/get-enrollments`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        accessToken: accessToken,
      },
    });

    const dataFromAdobe = response.data.data;

    for await (const course of content.courses) {
      const courseId = course.video.courseId;            

      const matchingCourse = dataFromAdobe.filter(
        (course) => course.relationships.learningObject.data.id === courseId
      );

      if (matchingCourse && matchingCourse[0]) {
        const adobeCourseData = matchingCourse[0];        

        if (course.localId === "0.0" && adobeCourseData.attributes.hasPassed) {
          updatedContent.introSectionPassed = true;          
        }

        if (course.localId === "1.0" && adobeCourseData.attributes.hasPassed) {
          updatedContent.sectionOnePassed = true;          
        }
        
        if (course.localId === "2.0" && adobeCourseData.attributes.hasPassed) {
          updatedContent.sectionTwoPassed = true;
        }
                
        if (course.localId === "3.0" && adobeCourseData.attributes.hasPassed) {
          updatedContent.sectionThreePassed = true;
        }
  
        mergedVideoContent.push({
          ...course,
          enrollmentId: adobeCourseData.id,
          hasPassed: adobeCourseData.attributes.hasPassed,
          progressPercent: adobeCourseData.attributes.progressPercent,
          // hasPassed: false,
          // progressPercent: 0,          
        });
      }
    }
    
    updatedContent.courses = mergedVideoContent;

    return new Promise((resolve) => resolve(updatedContent));
  } catch (error) {
    return new Promise((resolve) => resolve(error.data));
  }
}
