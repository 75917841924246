import { application } from "../../data/data";
import axios from "axios";

// Request user object from Adobe using Access token
export function fetchUserWithAccessToken(accessToken) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/get-user`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        accessToken: accessToken      
      },
    })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return resolve(error);
      })
  );
}

export function patchUserFields(userId, fields) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/patch-user`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId: userId,
        fields: fields    
      },
    })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return resolve(error);
      })
  );
}

export function fetchUserWithId(userId, accessToken) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/get-users`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId: userId,
        accessToken: accessToken   
      },
    })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return resolve(error);
      })
  );
}