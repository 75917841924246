import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TagManager from 'react-gtm-module'

const initialState = {
  zommedInOnSectionWithLocalId: undefined,
  isZoomingOut: false,
  isZoomingIn: false,
  isZoomedIn: false,
  transformOrigin: {
    x: undefined,
    y: undefined,
  },
  sidebar: {
    isOpening: undefined,
    isOpen: undefined,
    isClosing: undefined,
    isClosed: true,
    content: undefined,
  },
  status: "idle",
};

export const zoomInOnTransformOriginAsync = createAsyncThunk(
  "map/zoomInOnOrignAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const zoomOutAsync = createAsyncThunk("map/zoomOutAsync", async () => {
  return new Promise((resolve) => resolve());
});

export const openSidebarWithContentAsync = createAsyncThunk(
  "map/openSidebarWithContentAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const closeSidebarClearContentAsync = createAsyncThunk(
  "map/closeSidebarClearContentAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const authSlice = createSlice({
  name: "map",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(zoomInOnTransformOriginAsync.pending, (state) => {
        state.isZoomingIn = true;        
      })
      .addCase(zoomInOnTransformOriginAsync.fulfilled, (state, action) => {
        state.zommedInOnSectionWithLocalId = action.payload.localId;
        state.isZoomedIn = false;
        state.isZoomedIn = true;
        state.transformOrigin = {
          x: action.payload.x,
          y: action.payload.y,
        };    
                
        TagManager.dataLayer({
          dataLayer: {
            event: 'map_view',
            map_view: `Zoom ${action.payload.title}`                       
          }
        })
      })
      .addCase(zoomOutAsync.pending, (state) => {
        state.isZoomingOut = true;
      })
      .addCase(zoomOutAsync.fulfilled, (state, action) => {
        state.zommedInOnSectionWithLocalId = undefined;
        state.isZoomingOut = false;
        state.isZoomedIn = false;
        state.transformOrigin = {
          x: "50%",
          y: "50%",
        };

        TagManager.dataLayer({
          dataLayer: {
            event: 'map_view',
            map_view: "Return to main map"                       
          }
        })
      })
      .addCase(openSidebarWithContentAsync.pending, (state) => {
        state.sidebar.isClosed = false;
        state.sidebar.isOpening = true;
      })
      .addCase(openSidebarWithContentAsync.fulfilled, (state, action) => {
        state.sidebar.content = action.payload;
        state.sidebar.isOpening = false;
        state.sidebar.isOpen = true;    
        
        TagManager.dataLayer({
          dataLayer: {
            event: 'view_course',
            course_name: action.payload.title                       
          }
        })
      })
      .addCase(closeSidebarClearContentAsync.pending, (state) => {
        state.sidebar.isOpen = false;
        state.sidebar.isClosing = true;
      })
      .addCase(closeSidebarClearContentAsync.fulfilled, (state, action) => {
        state.sidebar.content = undefined;
        state.sidebar.isClosing = false;
        state.sidebar.isClosed = true;
      });
  },
});

export default authSlice.reducer;
