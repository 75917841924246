import React from "react";

export default function PathOne() {
  return (
    <svg
      width="213"
      height="246"
      viewBox="0 0 213 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 185C12.5 185 24.5002 217.5 53.0002 236.5C66.5002 245.5 90.2552 250.436 103.5 233.5C112.5 221.992 102.5 192.008 119 187.008C144 179.432 158.192 202.62 176.778 190.008C193 175.508 179.376 155.556 167.5 145.508C148 129.008 152.914 94.0078 155 78.5078C157.423 60.5078 160.765 41.9135 175.778 23.0078C180.559 16.9874 189.721 8.27422 196.5 4.50781C201.395 1.78829 206.46 1.00781 212 1.00781"
        stroke="#4B4345"
        strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
