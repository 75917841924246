import React from "react";
import styled from "styled-components";

import paperTexture from "../../images/background/paper-texture.png";
import CrossBonesIcon from "../../images/icons/inline/CrossBonesIcon";

export default function MobileNotification() {  
  return (
    <Container className="MobileNotification">    
      <Content>        
        <SingleIcon>
            <CrossBonesIcon />
        </SingleIcon>
        <Heading>This Screen is Too Small</Heading>
        <Paragraph>Please visit Epyc Explorer on a larger screen to continue your journey.</Paragraph>                
      </Content>      
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  display: block;  
  align-items: center;
  justify-content: center;          

  @media screen and (min-height: 500px) and (min-width: 1050px) {        
        display: none;    
    }
`;

const Content = styled.div`
  width: 30%;
  max-width: 400px;
  min-width: 200px;
  background-image: url(${paperTexture});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: left 1s ease;
  min-height: 100px;
  top: 50%;
  left: 50%;
  padding: 60px;
  text-align: center;
`;

const SingleIcon = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  background-image: url(${paperTexture});
  background-size: 470px;
  background-position: center;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 45px;

    path {
      fill: var(--dark-brown-color);
    }
  }
`;

const Heading = styled.h3`
  color: var(--dark-brown-color);
  text-transform: uppercase;
  margin-top: 1.5rem;
  font-size: 21px;
`;

const Paragraph = styled.p`
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;
