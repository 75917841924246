import { application } from "../../data/data";
import axios from "axios";

// Request access token from Adobe using the code URL param 
// returned from the auth redirect flow
export function fetchAccessTokenWithCode(code) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/request-access`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code: code,
      },
    })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return resolve(error);
      })
  );
}

// Request access token from Adobe using an existing refresh token
export function fetchAccessTokenWithRefresh(refreshToken) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/refresh-access-token`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        refreshToken: refreshToken,
      },
    })
      .then((response) => {        
        return resolve(response);
      })
      .catch((error) => {                       
        return resolve({
          status: error.response.status,          
          heading: `Session Expired`,
          paragraph: `Oh no! Looks like your session has expired. Please log back in to continue your journey.` 
        });
      })
  );
}

export function checkAccessTokenAsync(accessToken) {
  return new Promise((resolve) =>
    axios({
      url: `${application.endpoints.functions}/validate-access-token`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        accessToken: accessToken,
      },
    })
      .then((response) => {        
        if (response.data.error) {
          return resolve({
            status: 200,          
            heading: `Session Expired`,
            paragraph: `Oh no! Looks like your session has expired. Please log back in to continue your journey.` 
          });
        }

        return resolve(response);
      })
      .catch((error) => {                       
        return resolve({
          status: error.response.status,          
          heading: `Session Expired`,
          paragraph: `Oh no! Looks like your session has expired. Please log back in to continue your journey.` 
        });
      })
  );
}
