import sectionOneThumbnail from "../images/content/section-one-thumbnail.jpg";

import hat from "../images/prizes/AMD_Mockup_hat.png";
import socks from "../images/prizes/AMD_Mockup_socks.png";
import yeti from "../images/prizes/AMD_Mockup_yeti.png";

export const siteUrl =
  process.env.NODE_ENV === "production"
    ? `https://epycexplorer.com`
    : `http://localhost:3000`;

export const contact = {
  email: "gcp@amd.com",
};

export const application = {
  urls: {
    login:
      process.env.NODE_ENV === "production"
        ? `https://captivateprime.adobe.com/oauth/o/authorize?client_id=9c2ba2d3-a7cb-414a-bf9f-7fe70cd8716a&redirect_uri=${siteUrl}/auth-redirect&scope=learner:read,learner:write&response_type=CODE&account=104096&connection=google-oauth2`
        : `https://captivateprime.adobe.com/oauth/o/authorize?client_id=9c2ba2d3-a7cb-414a-bf9f-7fe70cd8716a&redirect_uri=${siteUrl}/auth-redirect&scope=learner:read,learner:write&response_type=CODE&account=104096&connection=google-oauth2`,
  },
  routes: {
    home: `/`,
    auth: "/auth-redirect",
    privacyPolicy: "/privacy-policy",
  },
  endpoints: {
    functions:
      process.env.NODE_ENV === "production"
        ? `${siteUrl}/.netlify/functions`
        : `http://localhost:8070/.netlify/functions`,
    adobeLogout: "https://learningmanager.adobe.com/logout?accountId=104096",
    logout: `https://auth.epycexplorer.com/oidc/logout?client_id=Wv5fmw611MEXET84ITy9iIIsAvaInGFo`,
    redirectAfterLogout:
      "https://learningmanager.adobe.com/splogin?accountId=104096&isExternal=false&ssoId=979",
    home: siteUrl,
  },
};

export const content = {
  courses: [
    {
      title: "Start",
      localId: "0.0",
      sidebar: {
        heading: `Welcome to EPYC Explorer`,
        paragraph: `It's adventure time! Watch this video and hear from AMD Corporate VP of Server Sales Kevin Lensing and Google Cloud VP of Global Solutions Tom DeFeo  as they explain the benefits of EPYC Explorer and how it will help you and your customers win.`,
      },
      illustration: "shack",
      isIntroduction: true,
      video: {
        courseId: "course:9494440",
        instanceId: "course:9494440_10169489",
        duration: "2 mins 55 secs",
        thumbnail: sectionOneThumbnail,
      },
      position: {
        top: "50%",
        left: "17.75%",
        marginTop: "-6%",
        width: "6.4%",
      },
      zoomOrigin: {
        x: "-8%",
        y: "27%",
      },
      prerequisiteLocalIds: [],
    },
    {
      title: "Alps",
      localId: "1.0",
      sidebar: {
        heading: `Learn about our products when you summit the Alps`,
        paragraph: `Join Natalie Rybka, Global Product and Platforms Lead for Google Cloud at AMD and renowned rescue dog Boo, the great St. Bernard legend of the Alps as they help you dig out of your snow tunnels and win deals this year.`,
      },
      illustration: "shanty",
      isSectionOne: true,
      video: {
        courseId: "course:6756424",
        instanceId: "course:6756424_7289203",
        duration: "0 mins 45 secs",
        thumbnail: sectionOneThumbnail,
      },
      position: {
        top: "50%",
        left: "27.125%",
        marginTop: "8.75%",
        width: "7.25%",
      },
      zoomOrigin: {
        x: "11%",
        y: "85%",
      },
      prerequisiteLocalIds: ["0.0"],
      treasureChest: {
        title: "You've conquered the Alps!",
        paragraph:
          "Excellent work! You've summited some of the most majestic peaks in the world. We'll send you a pair of hiking socks to continue your climbing adventures. Now, it's time to discover what lies at the end of your journeys to the mysterious marshes and the blistering desert. Rumor has it there's more prizes to be uncovered...",
        image: socks,
        color: `bronze`,
        nextCourseLocalId: "2.0",
      },
    },
    {
      title: "Swiss Alps",
      localId: "1.1",
      sidebar: {
        heading: `Check out the views of the GCE C3D series VMs from the top of the Swiss Alps`,
        paragraph: `The top of the Swiss Alps is closer than you think. AMD's Allen Leibovitch and Google's Sanket Modi will be your guides as they break down Google Cloud's C3D VMs powered by AMD processors for Google Compute Engine (GCE).`,
      },
      prerequisiteLocalIds: ["0.0", "1.0"],
      isSupplementalContent: true,
      parentTitle: "Alps",
      parentLocalId: "1.0",
      video: {
        courseId: "course:6756426",
        instanceId: "course:6756426_7289205",
        duration: "10 mins 23 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "50%",
        offsetLeft: "-50%",
      },
      zoomOrigin: {
        x: "11%",
        y: "85%",
      },
    },
    {
      title: "Bavarian Alps",
      localId: "1.2",
      sidebar: {
        heading: `Conquer the Bavarian Alps by learning about Confidential Computing and SNP`,
        paragraph: `Join your guide, Google Product Manager Joanna Young, as she guides you to the top of the Bavarian Alps while explaining Confidential Computing and Secure Nested Paging. Help your customers secure their data in the cloud with hardware-based encryption using AMD EPYC processors.`,
      },
      prerequisiteLocalIds: ["0.0", "1.0", "1.1"],
      isSupplementalContent: true,
      parentTitle: "Alps",
      parentLocalId: "1.0",
      video: {
        courseId: "course:6756476",
        instanceId: "course:6756476_7289255",
        duration: "11 mins 59 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "-115%",
        offsetLeft: "0",
      },
      zoomOrigin: {
        x: "11%",
        y: "85%",
      },
    },
    {
      title: "French Alps",
      localId: "1.3",
      sidebar: {
        heading:
          `Discover how Google Cloud VMs with AMD EPYC processors drive better AI performance in the French Alps`,
        paragraph:
          `The summit of the French Alps is within reach. Make your push by learning how Google Cloud VMs with AMD EPYC processors provide your customers with cost and performance efficiencies for their AI workloads.`,
      },
      prerequisiteLocalIds: ["0.0", "1.0", "1.1", "1.2"],
      isSupplementalContent: true,
      parentTitle: "Alps",
      parentLocalId: "1.0",
      video: {
        courseId: "course:6756477",
        instanceId: "course:6756477_7289256",
        duration: "10 mins 32 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "50%",
        offsetLeft: "50%",
      },
      zoomOrigin: {
        x: "11%",
        y: "85%",
      },
    },
    {
      title: "Marsh",
      localId: "2.0",
      sidebar: {
        heading: `Lift the curtain on our sales programs in the mysterious marshes`,
        paragraph: `Join fellow explorer Kate Bancroft in learning about the various sales programs that will help you accelerate your sales opportunities as you wade through mysterious marshes.`,
      },
      illustration: "bridge",
      isSectionTwo: true,
      video: {
        courseId: "course:6756478",
        instanceId: "course:6756478_7289257",
        duration: "1 min 11 secs",
        thumbnail: sectionOneThumbnail,
      },
      position: {
        top: "50%",
        left: "49%",
        marginTop: "-5%",
        width: "17%",
      },
      zoomOrigin: {
        x: "65%",
        y: "23%",
      },
      prerequisiteLocalIds: ["0.0", "1.0", "1.1", "1.2", "1.3"],
      treasureChest: {
        title: "You've made it through the marsh!",
        paragraph:
          "We're glad to see you made it! You've just expertly navigated the mysterious marsh. We'll send you a Lululemon hat as a token of your journey. There's only one more place to explore: the blistering desert. Keep going to see if the final prize is real or a mirage...",
        image: hat,
        color: `silver`,
        nextCourseLocalId: "3.0",
      },
    },
    {
      title: "Everglades",
      localId: "2.1",
      sidebar: {
        heading: `Sail through the Everglades while exploring POC and Migration Center sales programs`,
        paragraph: `Hop aboard with your captain, Google Cloud GTM and Solution Specialist, Infrastructure Modernization Kalpan Raval as you sail the Everglades and learn about funding programs for your customers to test and migrate their workloads on Google Cloud VMs powered by AMD EPYC processors!`,
      },
      prerequisiteLocalIds: ["0.0", "1.0", "1.1", "1.2", "1.3", "2.0"],
      isSupplementalContent: true,
      parentTitle: "Marsh",
      parentLocalId: "2.0",
      video: {
        courseId: "course:6756479",
        instanceId: "course:6756479_7289258",
        duration: "9 mins 04 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "75%",
        offsetLeft: "-15%",
      },
    },
    {
      title: "Pantanal",
      localId: "2.2",
      sidebar: {
        heading: `Navigate partner acceleration programs in the waters of the Pantanal`,
        paragraph: `AMD's Kate Bancroft guides you through the world's largest wetlands and explains the partner acceleration programs and resources available to you and your customers.`,
      },
      prerequisiteLocalIds: ["0.0", "1.0", "1.1", "1.2", "1.3", "2.0", "2.1"],
      isSupplementalContent: true,
      parentTitle: "Marsh",
      parentLocalId: "2.0",
      video: {
        courseId: "course:6756481",
        instanceId: "course:6756481_7289260",
        duration: "13 mins 49 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "-135%",
        offsetLeft: "0",
      },
    },
    {
      title: "Amazon River Basin",
      localId: "2.3",
      sidebar: {
        heading: `Navigate sales programs through the Amazon River Basin`,
        paragraph: `Weave your way through the Amazon River Basin and learn how to leverage our sales programs with your guide, Google Partner Manager Agustin Di Franco.`,
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
      ],
      isSupplementalContent: true,
      parentTitle: "Marsh",
      parentLocalId: "2.0",
      video: {
        courseId: "course:6756482",
        instanceId: "course:6756482_7289261",
        duration: "5 mins 23 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "75%",
        offsetLeft: "15%",
      },
    },
    {
      title: "Desert",
      localId: "3.0",
      sidebar: {
        heading: `Brave the blistering desert to learn about real-world vertical and logo wins`,
        paragraph: `Join Caitlyn Chew, AMD's Cloud Alliance Manager and her trusty camel to see past the desert mirages and hear about AMD's triumphs to help you on your sales journey.`,
      },
      illustration: "farm",
      isSectionThree: true,
      video: {
        courseId: "course:6756483",
        instanceId: "course:6756483_7289262",
        duration: "1 min 22 secs",
        thumbnail: sectionOneThumbnail,
      },
      position: {
        top: "50%",
        left: "73%",
        marginTop: "-12%",
        width: "10.9%",
      },
      zoomOrigin: {
        x: "106%",
        y: "-6%",
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
        "2.3",
      ],
      treasureChest: {
        title: "You've survived the blistering desert!",
        paragraph:
          "You've discovered the oasis in the scorching desert heat! And great news, that prize wasn't a mirage. We're sending you a YETI cup so you'll never run out of water when you need it most.",
        image: yeti,
        color: `gold`,
        nextCourseLocalId: "4.0",
      },
    },
    {
      title: "Pyramids of Giza",
      localId: "3.1",
      sidebar: {
        heading: `Explore this technical customer win amongst the Great Pyramids of Giza`,
        paragraph: `The Great Pyramids of Giza are an ancient marvel. Explore a modern marvel with this real-world customer success story of how Renault HPC achieved their goals with Google Cloud C2D VMs powered by AMD EPYC processors.`,
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
        "2.3",
        "3.0",
      ],
      isSupplementalContent: true,
      parentTitle: "Desert",
      parentLocalId: "3.0",
      video: {
        courseId: "course:6756485",
        instanceId: "course:6756485_7289264",
        duration: "3 mins 58 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "90%",
        offsetLeft: "-30%",
      },
    },
    {
      title: "Timbuktu",
      localId: "3.2",
      sidebar: {
        heading: `Stock up for your journey by exploring this sales win in Timbuktu`,
        paragraph: `Explore the ancient trade powerhouse of Timbuktu in Africa with Elio Lombardi, Key Account Executive at Google. Watch as he guides you through a major sales victory with a customer testimonial from KeyBank.`,
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
        "2.3",
        "3.0",
        "3.1",
      ],
      isSupplementalContent: true,
      parentTitle: "Desert",
      parentLocalId: "3.0",
      video: {
        courseId: "course:6756487",
        instanceId: "course:6756487_7289266",
        duration: "8 mins 15 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "-160%",
        offsetLeft: "0",
      },
    },
    {
      title: "Joshua Tree",
      localId: "3.3",
      sidebar: {
        heading: `Explore the unique desert landscape of Joshua Tree with a testimonial from AMD customer Neural Magic`,
        paragraph: `Joshua Tree is as unique of a landscape as you'll ever discover. Learn how AMD Customer Neural Magic has created a unique use case to share with your customers with Neural Magic VP of Business Development Jay Marshall.`,
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
        "2.3",
        "3.0",
        "3.1",
        "3.2",
      ],
      isSupplementalContent: true,
      parentTitle: "Desert",
      parentLocalId: "3.0",
      video: {
        courseId: "course:6756488",
        instanceId: "course:6756488_7289267",
        duration: "8 mins 52 secs",
        thumbnail: sectionOneThumbnail,
      },
      marker: {
        offsetTop: "90%",
        offsetLeft: "30%",
      },
    },
    {
      title: "Finish",
      localId: "4.0",
      sidebar: {
        heading: `You've conquered the EPYC Explorer adventure!`,
        paragraph: `Congratulations! You've explored the world's greatest landmarks and have completed your sales journey. Great news: there's still more treasure to discover! Make sure to tag your relevant GCE and GKS sales opportunities with #AMDEPYCExplorer to earn an entry into a raffle for a Lululemon duffle bag.`,
      },
      illustration: "outro",
      isOutro: true,
      video: {
        courseId: "course:6756489",
        instanceId: "course:6756489_7289268",
        duration: "0 mins 47 secs",
        thumbnail: sectionOneThumbnail,
      },
      position: {
        top: "50%",
        left: "77.25%",
        marginTop: "7.75%",
        width: "11%",
      },
      zoomOrigin: {
        x: "115%",
        y: "91%",
      },
      prerequisiteLocalIds: [
        "0.0",
        "1.0",
        "1.1",
        "1.2",
        "1.3",
        "2.0",
        "2.1",
        "2.2",
        "2.3",
        "3.0",
        "3.1",
        "3.2",
        "3.3",
      ],
    },
  ],
};
