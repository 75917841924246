import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {  
  isOpening: undefined,
  isOpen: undefined,
  isClosing: undefined,
  isClosed: true,    
  message: {
    type: undefined,
    heading: undefined,
    paragraph: undefined,    
    button: {
      label: undefined,
      actionType: undefined,
      course: undefined      
    }
  },
  status: "idle",
};

export const openNotificationAsync = createAsyncThunk(
  "notification/openNotificationAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const closeNotificationAsync = createAsyncThunk(
  "notification/closeNotificationAsync",
  async () => {
    return new Promise((resolve) => resolve());
  }
);

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openNotificationAsync.pending, (state) => {
        state.isClosed = false;
        state.isOpening = true;
        state.status = `loading`;
      })
      .addCase(openNotificationAsync.fulfilled, (state, action) => {                    
        state.message = action.payload;  
        state.isOpening = false;        
        state.isOpen = true;                                              
        state.status = "idle";
      })
      .addCase(closeNotificationAsync.pending, (state) => {
        state.isOpen = false;
        state.isClosing = true;        
        state.status = `loading`;
      })
  },
});

export default errorSlice.reducer;
