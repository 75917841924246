import { openSidebarWithContentAsync } from "../../features/map/mapSlice";
import { useDispatch } from "react-redux";
import React from "react";

import styled from "styled-components";
import mapPin from "../../images/illustrations/map-pin.svg";

import VideoPlayIcon from "../../images/icons/inline/VideoPalyIcon";
import ContentCompleteIcon from "../../images/icons/inline/ContentCompleteIcon";
import LockIcon from "../../images/icons/inline/ContentLockedIcon";

export default function MapPin(props) {
  const dispatch = useDispatch();

  const classNames = `map-is-zoomed-in-${
    props.mapIsZommedIn
  } map-is-zoomed-on-this-marker-${
    props.zommedInOnSectionWithLocalId === props.course.localId
  }`;

  const onMapPinClick = (course) => {
    dispatch(openSidebarWithContentAsync(course));
  };

  return (
    <>
      {!props.course.isIntro &&        
        !props.course.isSupplementalContent &&
        props.hasIncompletePrerequisite && (
          <LockIconContainer>
            <LockIcon />
          </LockIconContainer>
        )}
      <Container className={classNames}>
        <Pin onClick={() => onMapPinClick(props.course)}>
          <img src={mapPin} alt="Map pin" />
          <PinImage>
            {props.course.hasPassed && <ContentCompleteIcon />}

            {!props.course.hasPassed && <VideoPlayIcon />}
            <img src={props.course.video.thumbnail} alt="" />
          </PinImage>
        </Pin>
      </Container>
    </>
  );
}

const Pin = styled.div`
  transform: translate3d(0px, 0px, 10px);
`;

const PinImage = styled.div`
  position: absolute;
  overflow: hidden;
  top: 5px;
  left: 8px;
  border-radius: 50%;
  width: 54px;
  height: 54px;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;

    circle {
      fill: transparent;
      stroke: var(--paper-color);
    }

    path {
      fill: var(--paper-color);
      stroke: var(--paper-color);
    }
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  transition-delay: 0.4s;
  transform: translateY(-50px);
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  z-index: 20;

  &.map-is-zoomed-in-true.map-is-zoomed-on-this-marker-true {
    opacity: 1;
    pointer-events: all;
    transform: translateY(-40px);
    transition-delay: 0.8s;
  }
`;

const LockIconContainer = styled.div`
  width: 50px;
  height: 50px;
  bakground: red;
  position: absolute;
  z-index: 100;
`;
