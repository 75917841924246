import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseId: undefined,  
  enrollmentId: undefined,
  lastViewedCourseId: undefined,
  lastViewedEnrollmentId: undefined,
  isOpening: undefined,
  isOpen: undefined,
  isClosing: undefined,
  isClosed: true,
  status: "idle",
};

export const openVideoPlayerAsync = createAsyncThunk(
  "video/openVideoPlayerAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const closeVideoPlayerAsync = createAsyncThunk(
  "video/closeVideoPlayerAsync",
  async () => {
    return new Promise((resolve) => resolve());
  }
);

export const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openVideoPlayerAsync.pending, (state) => {
        state.isClosed = false;
        state.isOpening = true;
        state.status = `loading`;
      })
      .addCase(openVideoPlayerAsync.fulfilled, (state, action) => {        
        state.courseId = action.payload.courseId;        
        state.enrollmentId = action.payload.enrollmentId;
        state.lastViewedCourseId = action.payload.courseId;
        state.lastViewedEnrollmentId = action.payload.enrollmentId;
        state.isOpening = false;
        state.isOpen = true;
        state.status = "idle";
      })
      .addCase(closeVideoPlayerAsync.pending, (state) => {
        state.isOpen = false;
        state.isClosing = true;
        state.status = `loading`;
      })
      .addCase(closeVideoPlayerAsync.fulfilled, (state) => {
        state.courseId = undefined;
        state.enrollmentId = undefined;
        state.isClosing = false;
        state.isClosed = true;
        state.status = "idle";
      });
  },
});

export default videoSlice.reducer;
