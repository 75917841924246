import React from "react";
import styled from "styled-components";

import { zoomOutAsync, closeSidebarClearContentAsync } from "../../features/map/mapSlice";
import { useSelector, useDispatch } from "react-redux";

import LeftArrowIcon from "../../images/icons/inline/LeftArrowIcon";

export default function ZoomOutButton(props) {
  const application = useSelector((state) => state);
  const dispatch = useDispatch();

  const onButtonClick = () => {
    if ( application.map.sidebar.isOpen ) {
      dispatch(closeSidebarClearContentAsync());

      setTimeout(() => {
        dispatch(zoomOutAsync());
      }, 600)  
    } else {
      dispatch(zoomOutAsync());
    }     
  };

  return (
    <Button
      className={`map-is-zoomed-in-${application.map.isZoomedIn}`}
      onClick={onButtonClick}
    >
      <Inline>
        <LeftArrowIcon />
        <span>Return to main map</span>
      </Inline>
    </Button>
  );
}

const Button = styled.div`
  position: absolute;
  top: 50%;
  left: 30px;
  z-index: 10;
  transform: translateX(-100px) translateY(-50%);
  transition: transform 0.5s ease-in-out 0.25s, opacity 0.5s ease-in-out 0.25s;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.map-is-zoomed-in-true {
    transition-delay: 0.75s;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0) translateY(-50%);
  }

  svg {
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(-5px);
    }
  }
`;

const Inline = styled.div`
  display: inline-block;
  border: solid 1px var(--dark-brown-color);
  text-transform: uppercase;
  font-weight: 600;
  background: var(--white-color);
  cursor: pointer;
  padding: 12px 18px;
  display: flex;

  svg {
    width: 20px;
    margin-right: 3px;
  }

  span {
    color: var(--dark-brown-color);
    font-size: 1.25rem;
    letter-spacing: 0.05rem;
    text-decoration: none;
    display: inline-block;
    margin-left: 5px;
  }
`;
