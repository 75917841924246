import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  fetchAccessTokenWithCode,
  fetchAccessTokenWithRefresh,
  checkAccessTokenAsync
} from "./authApi";

const initialState = {
  code: undefined,
  userId: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  isValidatingAccessToken: false,
  isValidAccessToken: false,
  error: undefined,
  status: "idle",
};

export const saveCodeParamAsync = createAsyncThunk(
  "auth/saveCodeParam",
  async (code) => {
    return new Promise((resolve) => resolve(code));
  }
);

export const requestAccessTokenWithCodeAsync = createAsyncThunk(
  "auth/requestAccessTokenWithCodeAsync",
  async (code) => {
    const response = await fetchAccessTokenWithCode(code);
    return response.data;
  }
);

export const requestAccessTokenWithRefreshAsync = createAsyncThunk(
  "auth/requestAccessTokenWithRefreshAsync",
  async (refreshToken) => {
    const response = await fetchAccessTokenWithRefresh(refreshToken);

    if (response && response.data) {     
      return response.data;
    }    

    return {
      error: true,
      ...response,
    };
  }
);

export const validateAccessTokenAsync = createAsyncThunk(
  "auth/validateAccessTokenAsync",
  async (accessToken) => {
    const response = await checkAccessTokenAsync(accessToken);
    
    if (response && response.data) {
      return response.data;
    }    

    return {
      error: true,
      ...response,
    };
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveCodeParamAsync.pending, (state) => {
        state.error = undefined
        state.status = `loading`;
      })
      .addCase(saveCodeParamAsync.fulfilled, (state, action) => {
        state.code = action.payload;
        state.error = undefined
        state.status = "idle";        
      })
      .addCase(requestAccessTokenWithCodeAsync.pending, (state) => {
        state.error = undefined
        state.status = `loading`;
      })
      .addCase(requestAccessTokenWithCodeAsync.fulfilled, (state, action) => {        
        state.userId = action.payload.user_id;
        state.accessToken = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.error = undefined   
        state.status = "idle";      
      })
      .addCase(requestAccessTokenWithRefreshAsync.pending, (state) => {
        state.error = undefined  
        state.status = `loading`;      
      })
      .addCase(
        requestAccessTokenWithRefreshAsync.fulfilled,
        (state, action) => {                        
          if (action.payload.error) {            
            state.error = action.payload;            
          }

          if (!action.payload.error) {
            state.userId = action.payload.user_id;
            state.accessToken = action.payload.access_token;
            state.refreshToken = action.payload.refresh_token;            
          }

          state.status = "idle";
        }
      )
      .addCase(validateAccessTokenAsync.pending, (state) => {
        state.isValidatingAccessToken = true;
      })
      .addCase(validateAccessTokenAsync.fulfilled, (state, action) => {
        if (action.payload.error) {            
          state.isValidatingAccessToken = false;
          state.isValidAccessToken = false;
          state.error = action.payload;                    
        }                

        if (!action.payload.error) {
          state.isValidatingAccessToken = false;
          state.isValidAccessToken = true;
          state.error = undefined;
        }
      });
  },
});

export const { storeCode } = authSlice.actions;
export const selectCode = (state) => state.auth.code;
export const selectStatus = (state) => state.auth.status;

export default authSlice.reducer;
