import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMergedContent, fetchUpdatedContent } from "./contentApi";
import TagManager from "react-gtm-module";

const initialState = {
  contentLoadedEvent: false,
  introSectionPassed: false,
  sectionOnePassed: false,
  sectionTwoPassed: false,
  sectionThreePassed: false,
  hasCompletedSection: false,
  completedSection: null,
  courses: undefined,
  status: "idle",
};

export const requestMergedContentAsync = createAsyncThunk(
  "content/requestMergedContentAsync",
  async (accessToken) => {
    const response = await fetchMergedContent(accessToken);
    return response;
  }
);

export const requestUpdatedContentAsync = createAsyncThunk(
  "content/requestUpdatedContentAsync",
  async (value) => {
    const response = await fetchUpdatedContent(value);
    return response;
  }
);

export const clearHasCompletedCourseAsync = createAsyncThunk(
  "content/clearHasCompletedCourseAsync",
  async () => {
    return new Promise((resolve) => resolve());
  }
);

export const setContentLoadedEventAsync = createAsyncThunk(
  "content/setContentLoadedEventAsync",
  async () => {
    return new Promise((resolve) => resolve());
  }
);

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestMergedContentAsync.pending, (state) => {
        state.status = `loading`;
      })
      .addCase(requestMergedContentAsync.fulfilled, (state, action) => {
        state.introSectionPassed = action.payload.introSectionPassed
        state.sectionOnePassed = action.payload.sectionOnePassed
        state.sectionTwoPassed = action.payload.sectionTwoPassed
        state.sectionThreePassed = action.payload.sectionThreePassed

        state.courses = action.payload.courses;
        state.status = `idle`;
      })
      .addCase(requestUpdatedContentAsync.fulfilled, (state, action) => {
        const incompleteCourses = [];

        const matchingCourse = action.payload.courses.filter(
          (course) => course.enrollmentId === action.payload.id
        )[0];

        if (action.payload.attributes.hasPassed === true) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'course_progress',
              course_progress_action: `Completed ${matchingCourse.title} video`                       
            }
          })
        }            

        const updatedCourse = {
          ...matchingCourse,
          progressPercent: action.payload.attributes.progressPercent,
          hasPassed: action.payload.attributes.hasPassed,
        };

        let topLevelCourse;

        if (updatedCourse.isSupplementalContent) {
          topLevelCourse = action.payload.courses.filter(
            (course) => course.localId === updatedCourse.parentLocalId
          )[0];
        } else {
          topLevelCourse = updatedCourse;
        }

        if (topLevelCourse.hasPassed === false) {
          incompleteCourses.push(topLevelCourse);
        }
        
        if (topLevelCourse.hasPassed === true) {
          if (topLevelCourse.localId === "0.0") {
            state.introSectionPassed = true;
          }
    
          if (topLevelCourse.localId === "1.0") {
            state.sectionOnePassed = true;
          }
    
          if (topLevelCourse.localId === "2.0") {
            state.sectionTwoPassed = true;
          }
    
          if (topLevelCourse.localId === "3.0") {
            state.sectionThreePassed = true;
          }
        }

        const supplementalCourses = action.payload.courses.filter(
          (course) => course.parentLocalId === topLevelCourse.localId
        );

        for (const supplementalCourse of supplementalCourses) {
          if (supplementalCourse.localId === updatedCourse.localId) {
            if (updatedCourse.hasPassed === false) {
              incompleteCourses.push(updatedCourse);
            }
          } else if (supplementalCourse.hasPassed === false) {
            incompleteCourses.push(supplementalCourse);
          }
        }

        if (!incompleteCourses.length) {
          state.hasCompletedSection = true;
          state.completedSection = topLevelCourse;
        }        

        for (let i = 0; i < state.courses.length; i++) {          
          if (state.courses[i].enrollmentId === action.payload.id) {
            state.courses[i].hasPassed = action.payload.attributes.hasPassed;
            state.courses[i].progressPercent =
              action.payload.attributes.progressPercent;
          }
        }        
      })
      .addCase(clearHasCompletedCourseAsync.fulfilled, (state, action) => {
        state.hasCompletedSection = false;
        state.completedSection = null;        
      })
      .addCase(setContentLoadedEventAsync.pending, (state, action) => {
        state.contentLoadedEvent = true;             
      })
      .addCase(setContentLoadedEventAsync.fulfilled, (state, action) => {
        state.contentLoadedEvent = false; 
      });
  },
});

export default contentSlice.reducer;
