import React from "react";

export default function PathOne() {
  return (
    <svg
      width="61"
      height="249"
      viewBox="0 0 61 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00116 1.50781C1.0011 19.0078 65.262 26.0078 59.0011 74.0078C54.5011 108.508 2.00116 122.205 2.00116 157.008C2.00116 160.659 5.00116 172.508 11.2512 177.508C21.4349 185.655 34.0011 183.008 47.1123 194.786C61.071 204.986 50.5012 247.008 26.0012 247.008"
        stroke="#4B4345"
        strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
