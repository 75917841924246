import { validateAccessTokenAsync } from "../../features/auth/authSlice";
import { openVideoPlayerAsync, closeVideoPlayerAsync } from "../../features/video/videoSlice";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ClockIcon from "../../images/icons/inline/ClockIcon";
import sidebarPaper from "../../images/background/sidebar-paper.png";
import VideoPlayer from "./VideoPlayer";

export default function Sidebar(props) {
  const application = useSelector((state) => state);

  const dispatch = useDispatch();

  const [sidebarClassNames, setSidebarClassNames] = useState();
  const [contentClassNames, setContentClassNames] = useState();
  const [sidebarContent, setSidebarCotnent] = useState();
  const [videoPlayer, setVideoPlayer] = useState();

  useEffect(() => {
    if (!sidebarContent || !sidebarContent.video) return;

    if (sidebarClassNames === "sidebar-is-visible-true") {
      const courseId = sidebarContent.video.courseId;
      const enrollmentId = sidebarContent.enrollmentId;

      dispatch(validateAccessTokenAsync(application.auth.accessToken));      

      dispatch(
        openVideoPlayerAsync({ courseId: courseId, enrollmentId: enrollmentId })
      );
    } else if (application.video.courseId) {
      setTimeout(() => {
        dispatch(closeVideoPlayerAsync())
      }, 500)      
    }
  }, [
    sidebarContent,
    sidebarClassNames,
    application.auth.accessToken,
    application.video.courseId,
    dispatch,
  ]);

  useEffect(() => {
    if (application.map.sidebar.isOpen === true) {
      setSidebarClassNames(`sidebar-is-visible-true`);
    }

    if (application.map.sidebar.isClosing === true) {
      setSidebarClassNames(`sidebar-is-visible-false`);
    }

    if (application.map.sidebar.isOpen === true) {
      setContentClassNames(`content-is-visible-true`);
    }

    if (application.map.sidebar.isClosed === true) {
      setContentClassNames(`content-is-visible-false`);
    }
  }, [application.map.sidebar]);

  useEffect(() => {
    if (application.map.sidebar.content) {
      setSidebarCotnent(application.map.sidebar.content);
    }
  }, [application.map.sidebar.content]);

  useEffect(() => {
    if (sidebarContent && sidebarContent.video) {
      if (application.auth.accessToken) {
        setVideoPlayer({
          courseId: sidebarContent.video.courseId,
          accessToken: application.auth.accessToken,
          duration: sidebarContent.video.duration,
        });
      }
    }
  }, [sidebarContent, application.auth.accessToken]);

  return (
    <Container className={`${sidebarClassNames}`}>
      <OverflowContainer>
        <Content className={`${contentClassNames}`}>          
          {sidebarContent && sidebarContent.video && (
            <VideoPlayerContainer>
              <VideoPlayer />
            </VideoPlayerContainer>
          )}
          {sidebarContent && videoPlayer && (
            <VideoTitle>
              <Title>{sidebarContent.title}</Title>
              <Duration>
                <ClockIcon />
                {videoPlayer.duration}
              </Duration>
            </VideoTitle>
          )}
          {sidebarContent && sidebarContent.sidebar.heading && (
            <Heading>{sidebarContent.sidebar.heading}</Heading>
          )}
          {sidebarContent && sidebarContent.sidebar.paragraph && (
            <Paragraph>{sidebarContent.sidebar.paragraph}</Paragraph>
          )}
        </Content>
      </OverflowContainer>
    </Container>
  );
}

const OverflowContainer = styled.div`
  max-height: 90vh;
`;

const VideoPlayerContainer = styled.div`  
  margin-bottom: 0; 
  border: none; 
  width: 100%;      
`;

const Container = styled.div`
  background-image: url(${sidebarPaper});
  background-size: cover;
  height: 100vh;
  width: 80vw;
  max-width: 1400px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: scroll;
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%) translateZ(0);
  will-change: transform;
  backface-visibility: hidden;

  &.sidebar-is-visible-true {
    transform: translateX(0) translateZ(0);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s linear;
  margin-top: -5vh;
  margin-bottom: -5vh;
  padding: 5vh 50px 5vh 150px;

  &.content-is-visible-true {
    opacity: 1;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const VideoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--dark-brown-color);
  margin-top: 0;
`;

const Duration = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--dark-brown-color);
  margin-top: 0;

  svg {
    margin-right: 4px;
  }
`;

const Heading = styled.h3`
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 768px;
`;

const Paragraph = styled.p`
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 768px;
`;
