import React from "react";
import styled, { keyframes } from "styled-components";

import dragonAnimationFrame01 from "../../images/animation-frames/dragon/dragon-frame-1.svg";
import dragonAnimationFrame02 from "../../images/animation-frames/dragon/dragon-frame-2.svg";
import dragonAnimationFrame03 from "../../images/animation-frames/dragon/dragon-frame-3.svg";
import dragonAnimationFrame04 from "../../images/animation-frames/dragon/dragon-frame-4.svg";
import dragonAnimationFrame05 from "../../images/animation-frames/dragon/dragon-frame-5.svg";
import dragonAnimationFrame06 from "../../images/animation-frames/dragon/dragon-frame-6.svg";
import dragonAnimationFrame07 from "../../images/animation-frames/dragon/dragon-frame-7.svg";

export default function AnimatedDragon() {
  return (
    <React.Fragment>
      <Dragon />
      <LoadAssets>
        <img src={dragonAnimationFrame01} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame02} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame03} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame04} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame05} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame06} alt={"Hidden dragon illustration"} />
        <img src={dragonAnimationFrame07} alt={"Hidden dragon illustration"} />
      </LoadAssets>
    </React.Fragment>
  );
}

const dragonAnimation = keyframes`
    0% {
        background-image: url("${dragonAnimationFrame01}");    
        transform: rotate(-1deg);  
    }

    4% {
        background-image: url("${dragonAnimationFrame02}");
    }

    8% {
        background-image: url("${dragonAnimationFrame03}");
    }

    12% {
        background-image: url("${dragonAnimationFrame04}");      
    }

    16% {
        background-image: url("${dragonAnimationFrame05}");
    }

    20% {
        background-image: url("${dragonAnimationFrame06}");
        transform: rotate(0deg);
    }

    24% {
        background-image: url("${dragonAnimationFrame07}");
    }

    28% {
        background-image: url("${dragonAnimationFrame01}");
    }

    100% {
        background-image: url("${dragonAnimationFrame01}");
    }
`;

const Dragon = styled.div`
  position: absolute;
  top: 45%;
  left: 61.5%;
  margin-top: 17%;
  width: 14%;
  height: 29%;
  transform: translate3d(0, 0, 10px);
  background-size: contain;
  background-position: cover;
  background-repeat: no-repeat;
  animation-name: ${dragonAnimation}, dragonRotation;
  animation-duration: 3s, 6s;
  animation-timing-function: steps(1, end), ease-in-out;
  animation-iteration-count: infinite, infinite;
`;

const LoadAssets = styled.div`
  display: none;
`;
