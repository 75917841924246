import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toCamelCase } from "../../data/utility";
import {
  fetchUserWithAccessToken,
  patchUserFields,
  fetchUserWithId,
} from "./user.Api";

const initialState = {
  shouldShowUserInfoForm: undefined,
  shouldShowFormSuccess: undefined,
  hasSkipUserInfoFormCookie: undefined,
  lastLoginDate: undefined,
  id: undefined,
  email: undefined,
  name: undefined,
  state: undefined,
  adobeFields: [],
  expectedAdobeFields: [
    {
      localName: toCamelCase("First Name"),
      nameInAdobe: "First Name",
      required: true,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("Last Name"),
      nameInAdobe: "Last Name",
      required: true,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("Company Name"),
      nameInAdobe: "Company Name",
      options: [
        "Google",
        "AMD",                
      ],
      required: true,
    },
    {
      localName: toCamelCase("Business Email"),
      fieldLabel: "Email",
      nameInAdobe: "Business Email",
      required: true,
    },
    {
      localName: toCamelCase("Business Phone Number"),
      nameInAdobe: "Business Phone Number",
      fieldLabel: "Phone Number",
      required: true,
      isPhoneNumber: true      
    },
    {
      localName: toCamelCase("Job Title"),
      nameInAdobe: "Job Title",
      required: true,
    },
    {
      localName: toCamelCase("Segment"),
      nameInAdobe: "Segment",
      options: ["Select", "Corporate", "Enterprise", "SMB"],
      required: true,
    },
    {
      localName: toCamelCase("Vertical"),
      nameInAdobe: "Vertical",      
      required: true,
    },
    {
      localName: toCamelCase("Location / Region"),
      nameInAdobe: "Location / Region",
      options: [
        "U.S. & Canada",
        "Latin America",
        "Asia Pacific & Japan",
        "Europe, Middle East & Africa",
      ],
      required: true,
    },        
    {
      localName: toCamelCase("Street 1"),
      nameInAdobe: "Street 1",
      required: true,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("Street 2"),
      nameInAdobe: "Street 2",
      required: false,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("City"),
      nameInAdobe: "City",
      required: true,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("State"),
      nameInAdobe: "State",
      required: true,
      layoutClassName: "half"
    },
    {
      localName: toCamelCase("Zip Code"),
      nameInAdobe: "Zip Code",
      required: true,
    },            
    {
      localName: toCamelCase("Country"),
      nameInAdobe: "Country",
      required: true
    }
  ],
  allAdobeFieldsFilled: undefined,
  status: "idle",
};

export const requestUserWithAccessTokenAsync = createAsyncThunk(
  "user/requestUserWithAccessTokenAsync",
  async (accessToken) => {
    const response = await fetchUserWithAccessToken(accessToken);
    return response.data;
  }
);

export const requestUserWithIdAsync = createAsyncThunk(
  "user/requestUserWithIdAsync",
  async (value) => {
    const response = await fetchUserWithId(value.userId, value.accessToken);
    return response.data;
  }
);

export const setAllAdobeFieldsFilledAsync = createAsyncThunk(
  "user/setAllAdobeFieldsFilledAsync",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const patchUserFieldsAsync = createAsyncThunk(
  "user/patchUserFieldsAsync",
  async (value) => {
    const response = await patchUserFields(value.userId, value.fields);
    return response.data;
  }
);

export const setHasSkipUserInfoFormCookie = createAsyncThunk(
  "user/setHasSkipUserInfoFormCookie",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const setShouldShowUserInfoForm = createAsyncThunk(
  "user/setShouldShowUserInfoForm",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const setShouldShowFormSuccess = createAsyncThunk(
  "user/setShouldShowFormSuccess",
  async (value) => {
    return new Promise((resolve) => resolve(value));
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestUserWithAccessTokenAsync.pending, (state) => {
        state.status = `loading`;
      })
      .addCase(requestUserWithAccessTokenAsync.fulfilled, (state, action) => {
        const data = action.payload.data;
        const attributes = data.attributes;
        const adobeFields = attributes.fields;                

        state.lastLoginDate = attributes.lastLoginDate;
        state.email = attributes.email;
        state.name = attributes.name === "undefined undefined" ? '' : attributes.name;
        state.state = attributes.state;

        if (adobeFields && Object.keys(adobeFields).length) {
          for (const [key, value] of Object.entries(adobeFields)) {
            state.adobeFields.push({
              localName: toCamelCase(key),
              nameInAdobe: key,
              value: value,
            });
          }
        }

        state.id = data.id;
        state.status = `idle`;
      })
      .addCase(setAllAdobeFieldsFilledAsync.fulfilled, (state, action) => {
        state.allAdobeFieldsFilled = action.payload;
        state.status = `idle`;
      })
      .addCase(patchUserFieldsAsync.fulfilled, (state, action) => {
        state.status = `idle`;
      })
      .addCase(requestUserWithIdAsync.fulfilled, (state, action) => {
        const data = action.payload.data;
        const attributes = data.attributes;
        const adobeFields = attributes.fields;

        const updatedAdobeFields = [];

        if (adobeFields && Object.keys(adobeFields).length) {
          for (const [key, value] of Object.entries(adobeFields)) {
            updatedAdobeFields.push({
              localName: toCamelCase(key),
              nameInAdobe: key,
              value: value,
            });
          }
        }

        state.adobeFields = updatedAdobeFields;
        let numberOfRequiredFields = 0;

        if (state.expectedAdobeFields.length) {
          for (let i = 0; i < state.expectedAdobeFields.length; i++) {
            if (state.expectedAdobeFields[i].required === true) {
              numberOfRequiredFields += 1;
            }
          }
        }

        state.allAdobeFieldsFilled =
          updatedAdobeFields.length === numberOfRequiredFields;
        state.status = `idle`;
      })
      .addCase(setHasSkipUserInfoFormCookie.fulfilled, (state, action) => {
        state.hasSkipUserInfoFormCookie = action.payload;
      })
      .addCase(setShouldShowUserInfoForm.fulfilled, (state, action) => {
        state.shouldShowUserInfoForm = action.payload;
      })
      .addCase(setShouldShowFormSuccess.fulfilled, (state, action) => {
        state.shouldShowFormSuccess = action.payload;
      });
  },
});

export default userSlice.reducer;
