import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlice";
import contentReducer from "../features/content/contentSlice";
import mapReducer from "../features/map/mapSlice";
import videoReducer from "../features/video/videoSlice";
import notificationReducer from "../features/notification/notificationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    map: mapReducer,
    video: videoReducer,    
    content: contentReducer,
    notification: notificationReducer 
  },
});
