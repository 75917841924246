import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import styled from "styled-components";
import BackgroundImage from "./BackgroundImage";
import RightArrowIcon from "../../images/icons/inline/RightArrowIcon";
import NotificationCloseIcon from "../../images/icons/inline/NotificationCloseIcon";

import paperTexture from "../../images/background/paper-texture.png";
import logo from "../../images/logos/EPYC_Explorer_Logo.svg";

export default function PrivacyPolicy() {
  return (
    <OverflowContainer>
      <Helmet>
        <title>Epyc Explorer | Privacy Policy</title>
      </Helmet>
      <BackgroundImage>
        <BackgroundOverlay />
        <Container>
          <PageContent>
            <CloseIconContainer>
              <Link to="/">
                <NotificationCloseIcon />
              </Link>
            </CloseIconContainer>
            <LogoContainer>
              <img src={logo} alt={"EPYC Explorer Logo"} />
            </LogoContainer>
            <h2>Privacy Policy</h2>
            <hr style={{ marginBottom: "1.9rem" }} />
            <h3>Personal Information We Collect and Use</h3>
            <p>
              “Personal information” means information that identifies, relates
              to, or describes, directly or indirectly, a particular individual,
              such as: name, address, email address, phone number, or persistent
              device identifier.
            </p>
            <p>
              When signing in using your Google login, we will collect your
              email address and name. This information is crucial for creating a
              personalized and seamless user experience. By utilizing the email
              and name data, we can accurately identify individual users,
              allowing us to correlate and track their progress, preferences,
              and interactions within our platform. This process enables us to
              offer a more tailored and efficient service, ensuring that users
              can pick up right where they left off, access their personalized
              settings, and receive relevant recommendations based on their
              unique journey and interactions with our service.
            </p>
            <p>
              We collect several categories of personal information from you,
              depending on the AMD product, service, or technology you use,
              including websites operated by AMD and its affiliates, social
              media pages, mobile applications, business communications
              platforms (e.g., Slack), and other online services (“Sites”), or
              information you otherwise provide to us through our interactions
              online and offline. We may also collect personal information about
              you from others. We use personal information for typical business
              management, operational and commercial purposes (including to
              defend and protect us and others from harm or legal claims, and as
              required by applicable law), and as described more specifically
              below:
            </p>
            <p>
              <strong>Online submissions:</strong> We collect personal
              information when you interact with us online, including:
            </p>
            <ul>
              <li>
                Personal identifiers and professional and employment
                information. We collect personal identifiers (such as your name,
                phone number, email address, postal address, username, and
                password) and professional and employment information (such as
                your job title, company name, and information about your company
                and job function) to respond to your requests, process and
                fulfill orders, register you for an AMD service, event, or
                program, facilitate surveys, administer promotions, campaigns,
                sweepstakes, and contests, perform Site functionality, manage
                accounts and preferences, provide downloadable content and
                online training and events, respond to customer service
                requests, and communicate with you for marketing and
                informational purposes (subject to the 'Direct Marketing'
                section below). To register for some events, AMD may collect
                date of birth and passport information. If you choose not to
                register on a Site, you may have limited functionality. For
                example, you will not be able to participate in forum
                discussions on a Site without registering.
              </li>
              <li>
                Message and product review content. We collect the messages you
                submit through contact, customer service, sign-up, forum,
                survey, purchase and product review forms and through the use of
                your account to use communications features on the Sites. We use
                this information to respond to and fulfill your requests, better
                understand your preferences, and provide, enhance, and
                personalize our product, service, and technology offerings and
                the Sites.
              </li>
              <li>
                Audio and visual information. We receive any digital photos you
                submit through the Sites and post them to public areas of the
                Sites (including our social media pages) at your direction.
              </li>
              <li>
                Commercial transaction information. We collect and generate
                commercial transaction information (such as records of products
                and services purchased) when you make purchases or register
                products on a Site. We may use this information to deliver and
                fulfill your orders, refer you to distributors and partners to
                assist you with your requests, improve our product and service
                offerings, tailor our marketing efforts, and administer
                accounts.
              </li>
              <li>
                Online training (including the AMD Arena Program), event and
                program information. We collect and generate registration and
                participation information related to online training and events,
                such as your results and leader board position. We may also
                collect personal identifiers and professional and employment
                information for employment verification to ensure you are
                eligible to participate in online training and redeem AMD reward
                points. We use the information to operate and administer the
                training and events.
              </li>
              <li>
                Inferences from the information listed above. AMD will use the
                information listed above to draw inferences about your
                preferences (such as your preferred products), to help us
                provide you with personalized content and offers, and to help us
                provide, enhance, and personalize our product, service, and
                technology offerings and the Sites.
              </li>
              <li>
                Job applications. We collect and use the personal information
                you submit through job applications and the careers Sites to
                consider you for employment and facilitate the communities and
                communications on the Sites. AMD provides additional information
                about its privacy practices related to recruiting through its
                careers Sites.
              </li>
            </ul>
            <p>
              <strong>Product experience data collection: </strong>Participating
              in AMD's User Experience Program allows you to share information
              with us so that we can improve and develop our products, services,
              and technologies. For more information about this program and how
              it affects your personal information, visit AMD User Experience.
            </p>
            <p>[...]</p>
            <p>
              Please visit{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.amd.com/en/legal/privacy.html"
              >
                https://www.amd.com/en/legal/privacy.html
              </a>
              &nbsp;for the complete privacy terms governing epycexplorer.com.
            </p>
            <ButtonContainer>
              <ButtonBlock>
                <Link to={"/"}>
                  <ButtonInline>
                    <span>Return to EPYC Explorer</span>
                    <RightArrowIcon />
                  </ButtonInline>
                </Link>
              </ButtonBlock>
            </ButtonContainer>
          </PageContent>
        </Container>
      </BackgroundImage>
    </OverflowContainer>
  );
}

const OverflowContainer = styled.div`
  height: 100vh;
  overflow: scroll;
`;

const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  position: relative;
  padding: 60px 1rem;
`;

const PageContent = styled.div`
  background-image: url(${paperTexture});
  padding: 4rem;
  border-radius: 30px;
  position: relative;

  h2 {
    margin-top: 0;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--dark-brown-color);
    margin-bottom: 25px;
  }

  h3 {
    font-family: var(--secondary-font);
    font-weight: 500;
  }

  p,
  strong {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    font-family: var(--secondary-font);
    color: var(--dark-brown-color);

    a {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      font-family: var(--secondary-font);
    }
  }

  strong {
    font-weight: 700;
  }

  ul {
    li {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      font-family: var(--secondary-font);
      color: var(--dark-brown-color);
      margin-bottom: 25px;
    }
  }
`;

const LogoContainer = styled.div`
  max-width: 70%;
  margin: 0 auto 30px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 30px;

  > span {
    font-family: var(--secondary-font);
    font-size: 18px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;

  a {
    display: inherit;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 0.5;
  pointer-events: none;
`;

export const ButtonBlock = styled.div`
  background-color: var(--gold-color);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
`;

export const ButtonInline = styled.div`
  display: inline-block;
  border: solid 1px var(--dark-brown-color);
  text-transform: uppercase;
  font-weight: 600;
  padding: 18px 24px;
  width: 100%;

  span {
    color: var(--dark-brown-color);
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
  }

  svg {
    width: 20px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;
