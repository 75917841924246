import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

import backgroundBaseLayer from "../images/background/base-layer.jpg";
import paperLandscape from "../images/background/map-paper-landscape-small-min.png";

import cloudOneImage from "../images/background/cloud-1.png";
import cloudTwoImage from "../images/background/cloud-2.png";
import cloudThreeImage from "../images/background/cloud-3.png";
import cloudFourImage from "../images/background/cloud-4.png";
import cloudFiveImage from "../images/background/cloud-5.png";

import treesImage from "../images/illustrations/trees.svg";
import mountainsImage from "../images/illustrations/mountains.svg";
import housesImage from "../images/illustrations/houses.svg";

import castleAnimationFrameOne from "../images/animation-frames/castle/castle-frame-1.svg";
import castleAnimationFrameTwo from "../images/animation-frames/castle/castle-frame-2.svg";
import castleAnimationFrameThree from "../images/animation-frames/castle/castle-frame-3.svg";
import castleAnimationFrameFour from "../images/animation-frames/castle/castle-frame-4.svg";
import castleAnimationFrameFive from "../images/animation-frames/castle/castle-frame-5.svg";

import amdGoogleCloudLogo from "../images/logos/amd-google-cloud-logo.svg";
import epycExplorerLogo from "../images/logos/EPYC_Explorer_Logo.svg";
import TagManager from "react-gtm-module";

import { application as data } from "../data/data";
import Button from "../components/inline/Button";

export default function Home(props) {
  useEffect(() => {
    document.body.classList.add("homepage");

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_title: `Home`                       
      }
    })

    return () => {
      document.body.classList.remove("homepage");
    };    
  }, []);

  return (
    <Container>
      <CloudOne />
      <CloudTwo />
      <CloudThree />
      <CloudFour />
      <CloudFive />
      <Content className="content">
        <BackgroundImage />
        <PaperTexture>
          <Trees />
          <Mountains />
          <Houses />
          <Castle />
          <HiddenCastleOne />
          <HiddenCastleTwo />
          <HiddenCastleThree />
          <HiddenCastleFour />
          <HiddenCastleFive />
        </PaperTexture>
        <Rows>
          <TopRow>
            <AmdLogoContainer>
              <img src={amdGoogleCloudLogo} alt="AMD Google Cloud logo" />
            </AmdLogoContainer>
            <EpycExplorerLogoContainer>
              <img src={epycExplorerLogo} alt="Application logo" />
            </EpycExplorerLogoContainer>
          </TopRow>
          <MiddleRow>
            <ParagraphContainer>
              <ParagraphText style={{ marginTop: 0 }}>
                Welcome to EPYC Explorer! AMD and Google experts will be your
                guides as you explore the world's greatest landmarks and
                discover valuable treasures. From the mysterious marshes, to the
                soaring Alps, to the blistering desert, there's an entire
                exciting sales world to discover.
              </ParagraphText>
              <ParagraphText>
                In this interactive video sales training program, you'll learn
                about AMD and Google Cloud solutions, how to position them to
                your customers, and the sales programs available so you can win
                deals and become a certified EPYC Explorer.
              </ParagraphText>
            </ParagraphContainer>
            <ButtonContainer>
              <Button isExternal={true} path={data.urls.login}>
                Login / Register
              </Button>
            </ButtonContainer>
            <ParagraphContainer style={{ marginBottom: 0 }}>
              <DataHandlingDisclosure>
                When signing in with your Google account or email, we collect
                your name, email, mailing address, company, vertical, segment,
                region, and phone number for personalization. This enables us to
                offer tailored services and track your progress, while ensuring
                privacy, as detailed in our{" "}
                <Link to={`/privacy-policy`}>privacy policy</Link>.
              </DataHandlingDisclosure>
            </ParagraphContainer>
          </MiddleRow>
          <BottomRow>
            <LegalText>
              <p>
                <a href="mailto:gcp@amd.com">
                  Contact the Google Cloud AMD team
                </a>{" "}
                <span className="pipe" />
                <Link to={data.routes.privacyPolicy}>Privacy Policy</Link>
                <span className="pipe" />
                <a
                  href="/EPYC-Explorer-Terms-and-Conditions-2024.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </p>
            </LegalText>
          </BottomRow>
        </Rows>
      </Content>
    </Container>
  );
}

const fadeInKeyframes = keyframes`
  0% {
      opacity: 0;      
  }

  100% {
      opacity: 1;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2f33;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.125s;
  animation-fill-mode: forwards;
  min-height: var(--content-min-height);
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1050px) {
    min-height: 100vh;
    height: auto;
  }
`;

const Content = styled.div`
  min-height: var(--content-min-height);
`;

const BackgroundImage = styled.div`
  background-image: url(${backgroundBaseLayer});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  transition: opacity 0.5s linear 0s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: var(--content-min-height);
`;

const PaperTexture = styled.div`
  background-image: url(${paperLandscape});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  max-width: 1700px;
  left: 50%;
  top: 0;
  width: 80%;
  height: 100%;
  transform: translate3d(-50%, 0, 0px);
  min-height: var(--content-min-height);

  @media screen and (max-width: 1050px) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }
`;

const cloudBaseStyles = css`
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  width: 50vw;
  height: 50vh;
  z-index: 20;
  transform: translateZ(0);
  backface-visibility: hidden;
  opacity: 0.6;
`;

const cloudOneKeyframes = keyframes`
    0% {
        top: -10%;
        left: -30%;      
    }

    50% {
        top: -10%;
        left: -20%;
    }

    100% {
        top: -10%;
        left: -30%;
    }
`;

const CloudOne = styled.div`
  ${cloudBaseStyles}
  background-image: url(${cloudOneImage});
  top: -10%;
  left: -30%;
  transition-delay: 0.5s;
  animation-name: ${cloudOneKeyframes};
  animation-duration: 28s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const cloudTwoKeyframes = keyframes`
    0% {
        top: 0%;
        right: -10%;      
    }

    50% {
        top: 0%;
        right: 0%;
    }

    100% {
        top: 0%;
        right: -10%;
    }
`;

const CloudTwo = styled.div`
  ${cloudBaseStyles}
  background-image: url(${cloudTwoImage});
  top: 0%;
  right: -10%;
  transition-delay: 0.6s;
  animation-name: ${cloudTwoKeyframes};
  animation-duration: 32s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const cloudThreeKeyframes = keyframes`
    0% {
        bottom: -40%;
        left: -10%;      
    }

    50% {
        bottom: -35%;
        left: 10%;
    }

    100% {
        bottom: -40%;
        left: -10%;
    }
`;

const CloudThree = styled.div`
  ${cloudBaseStyles}
  background-image: url(${cloudThreeImage});
  bottom: -40%;
  left: -10%;
  transition-delay: 0.8s;
  animation-name: ${cloudThreeKeyframes};
  animation-duration: 30s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const cloudFourKeyframes = keyframes`
    0% {
        bottom: -10%;
        right: -10%;      
    }

    50% {
        bottom: 0%;
        right: 0%;
    }

    100% {
        bottom: -10%;
        right: -10%;
    }
`;

const CloudFour = styled.div`
  ${cloudBaseStyles}
  background-image: url(${cloudFourImage});
  bottom: -10%;
  right: -10%;
  transition-delay: 0.7s;
  animation-name: ${cloudFourKeyframes};
  animation-duration: 27s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const cloudFiveKeyframes = keyframes`
    0% {
        bottom: 0%;
        left: 20%;      
    }

    50% {
        bottom: 0%;
        left: -20%;
    }

    100% {
        bottom: 0%;
        left: 20%;
    }
`;

const CloudFive = styled.div`
  ${cloudBaseStyles}
  background-image: url(${cloudFiveImage});
  bottom: 0%;
  left: 20%;
  transition-delay: 1s;
  animation-name: ${cloudFiveKeyframes};
  animation-duration: 60s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const Trees = styled.div`
  background-image: url(${treesImage});
  position: absolute;
  background-size: 22.59% auto;
  background-position: 17px 22px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
`;

const Mountains = styled.div`
  background-image: url(${mountainsImage});
  position: absolute;
  background-size: 28.08% auto;
  background-position: calc(100% - 67px) 22px;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
`;

const Houses = styled.div`
  background-image: url(${housesImage});
  position: absolute;
  background-size: 18.44% auto;
  background-position: 17px calc(100% - 22px);
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

const castleKeyframes = keyframes`
    0% {
        background-image: url(${castleAnimationFrameOne});            
    }

    20% {
        background-image: url(${castleAnimationFrameTwo});
    }

    40% {
        background-image: url(${castleAnimationFrameThree});
    }

    60% {
        background-image: url(${castleAnimationFrameFour});
    }

    80% {
        background-image: url(${castleAnimationFrameFive});
    }

    100% {
        background-image: url(${castleAnimationFrameOne});            
    }
`;

const Castle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 31.4% auto;
  background-position: 98% 98%;
  background-repeat: no-repeat;
  animation-name: ${castleKeyframes};
  animation-duration: 1s;
  animation-timing-function: steps(1);
  animation-iteration-count: infinite;
  opacity: 0.5;
  -webkit-mask-image: url(${paperLandscape});
  -webkit-mask-position-x: right;
  -webkit-mask-position-y: bottom;
`;

const HiddenCastleOne = styled.div`
  background-image: url(${castleAnimationFrameOne});
  width: 0;
`;

const HiddenCastleTwo = styled.div`
  background-image: url(${castleAnimationFrameTwo});
  width: 0;
`;

const HiddenCastleThree = styled.div`
  background-image: url(${castleAnimationFrameThree});
  width: 0;
`;

const HiddenCastleFour = styled.div`
  background-image: url(${castleAnimationFrameFour});
  width: 0;
`;

const HiddenCastleFive = styled.div`
  background-image: url(${castleAnimationFrameFive});
  width: 0;
`;

const Rows = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-template-rows: 2fr 6fr 1fr;
  position: relative;
  z-index: 999;
  padding-top: 90px;
  padding-bottom: 90px;
  min-height: calc(var(--content-min-height) * 0.8);

  @media screen and (max-width: 1050px) {
    grid-template-rows: 2fr 4fr 1fr;
  }

  @media screen and (max-width: 719px) {
    grid-template-rows: 2fr 6fr 1fr;
  }
`;

const TopRow = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1050px) {
    align-self: center;
  }
`;

const MiddleRow = styled.div`
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media screen and (max-width: 1050px) {
    width: 85%;
  }
`;

const BottomRow = styled.div`
  align-self: flex-end;
`;

const LegalText = styled.div`
  position: relative;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;

  > p {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1050px) {
      flex-direction: column;
    }
  }

  .pipe {
    display: inline-block;
    width: 2px;
    height: 17px;
    background-color: #4f453c;
    margin: 0 5px;

    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  a {
    text-decoration: none !important;
    font-family: "Ten Oldstyle";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #4f453c;
    letter-spacing: 0.5px;
  }
`;

const AmdLogoContainer = styled.div`
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  img {
    position: relative;
    width: 180px;
  }
`;

const EpycExplorerLogoContainer = styled.div`
  position: relative;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
  margin-top: 21px;

  img {
    width: 320px;
  }
`;

const ButtonContainer = styled.div`
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;

  > div {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ParagraphContainer = styled.div`
  position: relative;
  max-width: 720px;
  margin: 1rem 0;
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
`;

const ParagraphText = styled.p`
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--secondary-font);
  color: var(--dark-brown-color);
`;

const DataHandlingDisclosure = styled.p`
  font-size: 12px;
  font-family: var(--secondary-font);
  color: var(--dark-brown-color);
  letter-spacing: 0.2px;
  line-height: 21px;

  a {
    font-size: 12px;
    font-family: var(--secondary-font);
    letter-spacing: 0.2px;
    line-height: 21px;
  }
`;
