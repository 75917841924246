import React from "react";
import styled from "styled-components";

export default function Loading(props) {
    return (
        <Section />                    
    )
}

const Section = styled.section`
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`