import React from "react";

export default function VideoPlayIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="17.5"
        transform="rotate(-90 18 18)"
        fill="#F3EBE3"
        stroke="black"
      />
      <path d="M26 18L13.25 24.9282L13.25 11.0718L26 18Z" fill="#4F453C" />
    </svg>
  );
}
