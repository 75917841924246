import React from "react";
import styled, { keyframes } from "styled-components";

import shipAnimationFrame01 from "../../images/animation-frames/ship/ship-frame-1.svg";
import shipAnimationFrame02 from "../../images/animation-frames/ship/ship-frame-2.svg";
import shipAnimationFrame03 from "../../images/animation-frames/ship/ship-frame-3.svg";
import shipAnimationFrame04 from "../../images/animation-frames/ship/ship-frame-4.svg";
import shipAnimationFrame05 from "../../images/animation-frames/ship/ship-frame-5.svg";

export default function AnimatedShip() {
  return (
    <React.Fragment>
      <Ship />
      <LoadAssets>
        <img src={shipAnimationFrame01} alt={"Hidden ship illustration"} />
        <img src={shipAnimationFrame02} alt={"Hidden ship illustration"} />
        <img src={shipAnimationFrame03} alt={"Hidden ship illustration"} />
        <img src={shipAnimationFrame04} alt={"Hidden ship illustration"} />
        <img src={shipAnimationFrame05} alt={"Hidden ship illustration"} />
      </LoadAssets>
    </React.Fragment>
  );
}

const shipAnimation = keyframes`
  0% {
    background-image: url("${shipAnimationFrame01}");            
  }

  20% {
    background-image: url("${shipAnimationFrame02}");
  }

  40% {
    background-image: url("${shipAnimationFrame03}");
  }

  60% {
    background-image: url("${shipAnimationFrame04}");
  }

  80% {
    background-image: url("${shipAnimationFrame05}");
  }

  100% {
    background-image: url("${shipAnimationFrame01}");            
  }
`;

const LoadAssets = styled.div`
  display: none;
`;

const Ship = styled.div`
  transform: translate3d(0, 0, 10px);
  position: absolute;
  top: 50%;
  left: 8%;
  margin-top: -7%;
  width: 14%;
  height: 14%;
  transform: translate3d(0, 0, 10px);
  background-size: contain;
  background-position: cover;
  background-repeat: no-repeat;
  animation-name: ${shipAnimation}, dragonRotation;
  animation-duration: 2s, 5s;
  animation-timing-function: steps(1, end), ease-in-out;
  animation-iteration-count: infinite, infinite;
`;
