import React from "react";

export default function TrailIcon() {
  return (
    <svg
      width="85"
      height="36"
      viewBox="0 0 85 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.417 5.66753C75.8842 6.48386 74.6297 6.25426 73.1867 7.20665C72.0221 7.9762 69.7742 8.85631 68.6738 9.7194C67.7875 10.4124 66.9226 11.7815 66.0791 12.5383C65.5139 11.5519 65.8265 11.284 65.2742 10.2849C64.5677 9.00511 61.7803 5.99066 61.0995 4.69814C60.2518 3.0825 60.6371 2.45324 59.8322 0.816339C58.9244 -1.0289 56.1542 0.586748 57.0576 2.42773C57.7941 3.92858 58.6162 6.29678 59.3911 7.77637C60.7913 10.4507 62.1828 12.2151 63.6942 14.8299C62.7737 15.7781 61.3479 16.4924 60.5301 17.5043C59.0358 19.3538 58.2651 21.5731 57.0491 23.6012C56.03 25.3019 54.5785 27.0196 53.7008 28.8138C53.1013 30.034 53.0457 31.3095 52.4933 32.5638C51.6713 34.426 54.4372 36.0502 55.2679 34.1752C56.6209 31.1097 58.0767 28.1038 59.7808 25.2551C61.3864 22.5723 63.2147 20.0255 65.3855 17.6998C66.1048 18.8946 68.1386 22.8189 68.8794 23.9966C70.6348 26.7942 71.1486 26.8112 73.0412 29.5238C74.2101 31.199 76.9932 29.6089 75.8157 27.9124C74.878 26.5689 73.9103 25.8291 73.0069 24.4643C72.0992 23.091 70.9902 20.017 70.1125 18.6267C69.209 17.1896 68.5925 16.824 67.7276 15.3699C68.8494 14.3325 70.896 12.0918 72.1035 11.1692C74.4199 9.40477 76.9675 9.31549 79.5664 7.9677C81.2834 7.07484 82.1012 6.06719 83.9252 5.3444C85.822 4.59185 84.9956 1.50937 83.0731 2.27042C82.5122 2.49151 81.8186 3.2143 81.2748 3.38437C79.6478 3.89457 78.9927 4.8342 77.417 5.67603V5.66753Z"
        fill="#475A72"
      />
      <path
        d="M24.6281 22.2917L23.973 20.71C23.1852 21.2458 22.6158 22.0111 21.3527 21.7985C21.8365 24.0391 23.318 22.9932 24.6281 22.2959V22.2917Z"
        fill="#475A72"
      />
      <path
        d="M0.787727 14.7789C1.66119 15.4549 3.03133 16.097 3.64361 16.8495C3.82344 16.4626 4.57274 15.8716 4.70119 15.5442C3.41668 14.4345 2.14074 13.4099 0.894769 12.4745C-0.458243 13.3886 -0.0771729 14.1072 0.792009 14.7789H0.787727Z"
        fill="#475A72"
      />
      <path
        d="M53.795 13.6607C54.981 13.9201 56.5609 14.0604 57.2803 14.4898L57.5029 12.7424C55.6832 12.3002 53.2555 11.3606 52.6646 12.1089C51.8168 13.0187 52.6132 13.4014 53.7993 13.6607H53.795Z"
        fill="#475A72"
      />
      <path
        d="M32.1896 17.5C30.6867 19.2645 27.5697 16.4116 27.3342 21.1012C29.0126 20.1828 31.132 19.6726 32.819 19.0264L32.1896 17.5Z"
        fill="#475A72"
      />
      <path
        d="M49.2864 13.6097L48.7726 11.9218C47 11.7134 44.8934 12.6488 44.4866 13.091C43.322 16.1182 47.4795 12.972 49.2906 13.6097H49.2864Z"
        fill="#475A72"
      />
      <path
        d="M40.312 15.8206L39.6354 14.1284C38.1369 14.6301 36.0174 15.1403 35.6235 16.0077C34.9042 19.4388 38.6164 15.8886 40.3162 15.8248L40.312 15.8206Z"
        fill="#475A72"
      />
      <path
        d="M11.1965 20.591C11.2693 20.1701 11.7617 19.8257 11.8173 19.2432C10.4172 18.5417 8.98715 17.8401 7.55279 17.1599C5.2835 19.5876 10.0704 19.5366 11.1922 20.591H11.1965Z"
        fill="#475A72"
      />
      <path
        d="M18.5738 23.1463C18.5653 22.7211 18.9806 21.8623 19.1562 21.3521C17.5291 20.9396 15.9363 20.5272 14.4078 20.1191C12.5795 23.2441 16.8398 21.8963 18.5738 23.1463Z"
        fill="#475A72"
      />
    </svg>
  );
}
