import React from "react";

export default function CompleteIcon(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="17.5"
        transform="rotate(-90 18 18)"
        fill="#B08D50"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4091 21.2836L10.3523 17.209L9 18.5672L14.4091 24L26 12.3582L24.6477 11L14.4091 21.2836Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}
