import React from "react";

export default function CheckCircleWhiteOnBrown(props) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="#B08D50"
        stroke="#4F453C"
        strokeWidth="1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86364 13.5373L6.71591 11.3433L6 12.0746L8.86364 15L15 8.73134L14.2841 8L8.86364 13.5373Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}
