import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  saveCodeParamAsync,
  requestAccessTokenWithCodeAsync,
} from "../features/auth/authSlice";

import { useCookies } from "react-cookie";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function AuthRedirect(props) {
  const query = new URLSearchParams(useLocation().search);
  const codeQueryParam = query.get("code");

  const application = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [shouldRedirect, setShouldRedirect] = useState();
  
  const cookies = useCookies(["refreshToken"]);
  const setCookie = cookies[1];

  // If query param includes code, dispatch async reduc function
  // to save code param to local state.auth.code
  useEffect(() => {
    if (!application.code && codeQueryParam) {
      dispatch(saveCodeParamAsync(codeQueryParam));
    }
  }, [codeQueryParam, application.code, dispatch]);

  // If application code is set, dispatch async redux function
  // to fetch access token from Adobe
  useEffect(() => {
    if (application.code) {
      dispatch(requestAccessTokenWithCodeAsync(application.code));
    }
  }, [application.code, dispatch]);

  // If application refreshToken is set, build expirate for local
  // cookie, set refreshToken cookie in users browser. Set should redriect
  useEffect(() => {
    if (application.refreshToken) {
      const now = new Date();
      const expires = new Date();
      expires.setDate(now.getDate() + 1);

      setCookie("refreshToken", application.refreshToken, {
        path: "/",
        expires: expires,
      });
      setShouldRedirect(true);
    }
  }, [application.refreshToken, setCookie]);  

  return (
    <Section>
      {shouldRedirect ? (
        <Navigate to="/" replace={true} />
      ) : (
        <div>Loading...</div>
      )}
    </Section>
  );
}

const Section = styled.section``;
