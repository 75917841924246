import styled, { keyframes } from "styled-components";

export default function NotFound() {
  return <Heading>Not Found</Heading>;
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;           
  }

  100% {
    opacity: 1;
  }
`;

const Heading = styled.h1`
  opacity: 0;
  animation-name: ${fadeInAnimation};
  animation-duration: 0.3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;

