import {
  setAllAdobeFieldsFilledAsync,
  requestUserWithIdAsync,
} from "../../features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

import TreasureTracker from "./TreasureTracker";
import UserInfoForm from "./UserInfoForm";
import styled from "styled-components";
import Map from "./Map";

export default function Board(props) {
  const application = useSelector((state) => state);
  const dispatch = useDispatch();

  const adobeFields = application.user.adobeFields;
  const allAdobeFieldsFilled = application.user.allAdobeFieldsFilled;
  const expectedAdobeFields = application.user.expectedAdobeFields;

  const numberOfAdobeField = Object.keys(adobeFields).length;

  const calculateNumberOfRequiredFields = (expectedAdobeFields) => {
    if (expectedAdobeFields.length) {
      let numberOfRequiredFields = 0;

      for (let i = 0; i < expectedAdobeFields.length; i++) {
        if (expectedAdobeFields[i].required === true) {
          numberOfRequiredFields += 1;
        }
      }

      return numberOfRequiredFields;
    }
  };

  useEffect(() => {
    if (typeof allAdobeFieldsFilled === "undefined") {
      const numberOfRequiredAdobeFields =
        calculateNumberOfRequiredFields(expectedAdobeFields);

      if (numberOfAdobeField === numberOfRequiredAdobeFields) {
        dispatch(setAllAdobeFieldsFilledAsync(true));
      } else {
        dispatch(
          requestUserWithIdAsync({
            userId: application.user.id,
            accessToken: application.auth.accessToken,
          })
        ).then((response) => {
          const fieldsInResponse = response.payload.data.attributes.fields;
          const numberOfFieldsInResponse = fieldsInResponse
            ? Object.keys(fieldsInResponse).length
            : 0;

          if (numberOfFieldsInResponse === numberOfRequiredAdobeFields) {
            dispatch(setAllAdobeFieldsFilledAsync(true));
            return;
          }

          dispatch(setAllAdobeFieldsFilledAsync(false));
          return;
        });
      }
    }
  }, [
    adobeFields,
    expectedAdobeFields,
    numberOfAdobeField,
    allAdobeFieldsFilled,
    application.auth.accessToken,
    application.user.id,
    dispatch,
  ]);

  return (
    <Section>
      <Map />
      <TreasureTracker />
      <UserInfoForm />
    </Section>
  );
}

const Section = styled.section``;
