import React from "react";
import styled from "styled-components";

import RightArrowIcon from "../../images/icons/inline/RightArrowIcon";
import { Link } from "react-router-dom";

export default function Button(props) {
  const { isExternal, path, target } = props;

  if (path && isExternal) {
    if (target === "_blank") {
      return (
        <ButtonBlock>
          <ButtonInline>
            <a href={path} target="_blank" rel="noreferrer">
              <span>{props.children}</span>
              <RightArrowIcon />
            </a>
          </ButtonInline>
        </ButtonBlock>
      );
    }

    if (target !== "_blank") {
      return (
        <ButtonBlock>
          <ButtonInline>
            <a href={path}>
              <span>{props.children}</span>
              <RightArrowIcon />
            </a>
          </ButtonInline>
        </ButtonBlock>
      );
    }
  }

  if (path && !isExternal) {
    return (
      <ButtonBlock>
        <ButtonInline>
          <Link to={path}>
            <span>{props.children}</span>
            <RightArrowIcon />
          </Link>
        </ButtonInline>
      </ButtonBlock>
    );
  }

  return <div className="empty" />;
}

export const ButtonBlock = styled.div`
  background-color: var(--gold-color);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem;
  min-width: 60%;
  position: relative;
`;

export const ButtonInline = styled.div`
  display: inline-block;
  border: solid 1px var(--dark-brown-color);
  text-transform: uppercase;
  font-weight: 600;  
  width: 100%;

  span {
    color: var(--dark-brown-color);
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
  }

  a {    
    padding: 12px 24px 12px 26px;
    display: block;
  }

  svg {
    width: 20px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;
