import React from "react";
import styled from "styled-components";

import backgroundImage from "../../images/background/base-layer.jpg";

export default function BackgroundImage(props) {
  return <Background>{props.children}</Background>;
}

const Background = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  animation: fadeIn 1s forwards;
  overflow: hidden;
  position: relative;
  min-height: var(--content-min-height);
  min-width: var(--content-min-width);
  height: 100vh;
  width: 100vw;
`;
