import React from "react";
import styled, { css } from "styled-components";

import cloudOne from "../../images/background/cloud-1.png";
import cloudTwo from "../../images/background/cloud-2.png";
import cloudThree from "../../images/background/cloud-3.png";
import cloudFour from "../../images/background/cloud-4.png";
import cloudFive from "../../images/background/cloud-5.png";

export default function CloudsAboveMap(props) {
  return (
    <React.Fragment>
      <CloudSix className={`clouds-zoomed-in-${props.cloudsZoomedIn}`} />
      <CloudSeven className={`clouds-zoomed-in-${props.cloudsZoomedIn}`} />
      <CloudEight className={`clouds-zoomed-in-${props.cloudsZoomedIn}`} />
      <CloudNine className={`clouds-zoomed-in-${props.cloudsZoomedIn}`} />
      <CloudTen className={`clouds-zoomed-in-${props.cloudsZoomedIn}`} />
    </React.Fragment>
  );
}

const cloud = css`
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  width: 50vw;
  height: 50vh;
  opacity: 0;
  z-index: -1;
  transition: opacity 1.5s linear 0.75s;
`;

const CloudSix = styled.div`
  ${cloud}
  top: -20%;
  left: -30%;
  background-image: url(${cloudOne});
  animation: cloudSix 120s ease-in-out infinite;

  &.clouds-zoomed-in-false {
    opacity: 0.8;
  }
`;

const CloudSeven = styled.div`
  ${cloud}
  top: -18%;
  right: -17%;
  background-image: url(${cloudTwo});
  animation: cloudSeven 60s ease-in-out infinite;

  &.clouds-zoomed-in-false {
    opacity: 0.8;
  }
`;

const CloudEight = styled.div`
  ${cloud}
  bottom: -48%;
  left: -20%;
  background-image: url(${cloudThree});
  animation: cloudEight 150s ease-in-out infinite;

  &.clouds-zoomed-in-false {
    opacity: 0.7;
  }
`;

const CloudNine = styled.div`
  ${cloud}
  bottom: -30%;
  right: -20%;
  background-image: url(${cloudFour});
  animation: cloudNine 160s ease-in-out infinite;

  &.clouds-zoomed-in-false {
    opacity: 0.8;
  }
`;

const CloudTen = styled.div`
  ${cloud}
  bottom: 4%;
  left: 5%;
  background-image: url(${cloudFive});

  &.clouds-zoomed-in-false {
    opacity: 0.8;
  }
`;
