import React from "react";

export default function PathOne() {
  return (
    <svg
      width="93"
      height="108"
      viewBox="0 0 93 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5011 80.5078C19.5137 80.5078 35.1174 111.844 56.5011 105.508C70.0011 101.508 73.3448 91.0078 74.0011 80.5078C75.0011 64.5078 64.1092 58.8427 56.5011 47.0078C51.0696 38.5589 52.0011 24.5078 57.0011 15.0078C65.0011 1.00784 79.0011 1.00781 92.0011 1.00781"
        stroke="#4B4345"
        strokeOpacity="0.7"
        strokeWidth="2"
        strokeLinecap="square"
        strokeDasharray="4 4"
      />
    </svg>
  );
}
