import React from "react";

export default function LockIcon() {
  return (
    <svg
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5003 10.4997C7.5003 9.67529 6.82441 8.99939 5.99999 8.99939C5.17557 8.99939 4.49967 9.67529 4.49967 10.4997C4.49967 11.3254 5.17557 12 5.99999 12C6.82441 12 7.5003 11.3254 7.5003 10.4997ZM3.74967 3.74954C3.74967 2.50473 4.75534 1.50033 6.00015 1.50033C7.24495 1.50033 8.25062 2.50473 8.25062 3.74954V5.24985H3.74967V3.74954ZM9.74953 5.24984V3.74953C9.74953 1.6803 8.07048 0 6 0C3.92952 0 2.25047 1.6803 2.25047 3.74953V5.24984H1.50031C0.674638 5.24984 0 5.92448 0 6.75016V14.2505C0 15.0749 0.674638 15.7495 1.50031 15.7495H10.4997C11.3254 15.7495 12 15.0749 12 14.2505V6.75016C12 5.92448 11.3254 5.24984 10.4997 5.24984H9.74953Z"
        fill="#4F453C"
      />
    </svg>
  );
}
