import { openNotificationAsync } from "../../features/notification/notificationSlice";

import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { application as data } from "../../data/data";

import MobileNotification from "./MobileNotification";
import BackgroundImage from "./BackgroundImage";
import React, { useEffect } from "react";

import "normalize.css/normalize.css";

export default function Layout(props) {
  const application = useSelector((state) => state);
  
  const cookies = useCookies(["refreshToken"]);
  const removeCookie = cookies[2]

  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const emailDomainQueryParam = query.get("unauthorized_email_domain");  

  useEffect(() => {
    if (emailDomainQueryParam !== null) {
      removeCookie("refreshToken", { path: "/" });      

      dispatch(
        openNotificationAsync({
          type: `authenticationError`,
          heading: `Oops, you've hit a dead end!`,
          paragraph: `EPYC Explorer is for AMD and Google employees only. Make sure you sign up with your AMD or Google email address to begin your journey.`,
          button: {
            label: "Re-Try Login",
            actionType: "navigateToExternalUrl",
            externalUrl: data.urls.login,
          },
        })
      );
      document.body.classList.add("unauthorized-email-domain");
    }        
  }, [emailDomainQueryParam, removeCookie, dispatch]);

  useEffect(() => {
    if ( application.auth.error ) {
        removeCookie("refreshToken", { path: "/" });
        window.location.replace(data.urls.login)
    }
  }, [application.auth.error, removeCookie, dispatch])

  if (application.auth.error) {
    return <React.Fragment />;
  }

  if (emailDomainQueryParam) {
    return (
      <main>
        <BackgroundImage />
      </main>
    );
  }

  if (!application.auth.error && !emailDomainQueryParam) {
    return (
      <main>
        <Outlet />
        <MobileNotification />
      </main>
    );
  }  

  return <React.Fragment />;
}
