import { useSelector, useDispatch } from "react-redux";
import { clearHasCompletedCourseAsync } from "../../features/content/contentSlice";
import { closeNotificationAsync } from "../../features/notification/notificationSlice";
import {
  setShouldShowUserInfoForm,
  setShouldShowFormSuccess,
} from "../../features/user/userSlice";

import {
  zoomInOnTransformOriginAsync,
  openSidebarWithContentAsync,
  zoomOutAsync,
} from "../../features/map/mapSlice";

import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";

import LockIcon from "../../images/icons/inline/NotificationLockIcon";
import CrossBonesIcon from "../../images/icons/inline/CrossBonesIcon";
import MugIcon from "../../images/icons/inline/MugIcon";
import RightArrowIcon from "../../images/icons/inline/RightArrowIcon";
import TrailIcon from "../../images/icons/inline/TrailIcon";
import NotificationCloseIcon from "../../images/icons/inline/NotificationCloseIcon";
import TagManager from 'react-gtm-module'

import AnimatedCastle from "./AnimatedCastle";
import AnimatedTreasureChest, {
  AnimatedChest,
  chestOpenAnimation,
} from "./AnimatedChest";

import paperTexture from "../../images/background/paper-texture.png";

export default function Notification() {
  const application = useSelector((state) => state);

  const dispatch = useDispatch();

  const message = application.notification.message;

  const onNavigateToDifferentCourseClick = () => {
    if (application.map.isZoomedIn === true) {
      dispatch(closeNotificationAsync());
      dispatch(clearHasCompletedCourseAsync());

      setTimeout(() => {
        dispatch(zoomOutAsync());
      }, [500]);

      setTimeout(() => {
        dispatch(
          zoomInOnTransformOriginAsync({
            localId: message.button.course.localId,
            x: message.button.course.zoomOrigin.x,
            y: message.button.course.zoomOrigin.y,
          })
        );
      }, [2250]);
    }

    if (message.button && message.button.course) {
      const course = message.button.course;

      if (course.isOutro) {
        setTimeout(() => {
          dispatch(openSidebarWithContentAsync(course));
        }, 3500);
      }
    }

    if (application.map.isZoomedIn === false) {
      dispatch(closeNotificationAsync());

      setTimeout(() => {
        dispatch(
          zoomInOnTransformOriginAsync({
            localId: message.button.course.localId,
            x: message.button.course.zoomOrigin.x,
            y: message.button.course.zoomOrigin.y,
          })
        );
      }, [500]);
    }
  };

  const onOpenPlayerProfileClick = (event) => {
    event.preventDefault();
    dispatch(closeNotificationAsync());

    if (application.map.isZoomedIn) {
      dispatch(zoomOutAsync());
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'registration',
        registration_action: "Open profile from popup"                       
      }
    })

    setTimeout(() => {
      dispatch(setShouldShowUserInfoForm(true));      
    }, 1000);
  };

  const onOpenCourseInSidebarClick = () => {
    dispatch(closeNotificationAsync());

    setTimeout(() => {
      dispatch(openSidebarWithContentAsync(message.button.course));
    }, [500]);
  };

  const onNavigateToExternalUrlClick = () => {
    window.location.href = message.button.externalUrl;
  };

  const onCloseNotificationClick = () => {
    dispatch(closeNotificationAsync());
    dispatch(clearHasCompletedCourseAsync());

    setTimeout(() => {
      dispatch(zoomOutAsync());
    }, [500]);
  };

  const onCloseFormSuccess = () => {
    dispatch(closeNotificationAsync());

    setTimeout(() => {
      dispatch(setShouldShowUserInfoForm(false));
    }, 500);

    setTimeout(() => {
      dispatch(setShouldShowFormSuccess(false));
    }, 1500);
  };

  return (
    <Container className={`Notification is-visible-${application.notification.isOpen}`}>
      <Content
        className={`is-treasure-chest-${
          message.type === "completeTreasureChest"
        } is-complete-no-remaining-content-${
          message.type === "completeOutroNoRemainingContent"
        }`}
      >
        {message && message.type === "incompleteParent" && (
          <SingleIcon>
            <LockIcon />
          </SingleIcon>
        )}

        {message && message.type === "playerProfileFormSuccess" && (
          <SingleIcon>
            <MugIcon />
          </SingleIcon>
        )}

        {message && message.type === "profileComplete" && (
          <SingleIcon>
            <MugIcon />
          </SingleIcon>
        )}

        {message && message.type === "incompletePrerequisite" && (
          <SingleIcon>
            <LockIcon />
          </SingleIcon>
        )}

        {message && message.type === "completeTreasureChest" && (
          <LargeIcon>
            <LargeIconBackground
              style={{
                backgroundColor: `var(--${message.treasureChest.color}-color)`,
              }}
            />
            <AnimatedTreasureChest />
          </LargeIcon>
        )}

        {message && message.type === "completeOutroWithRemainingContent" && (
          <SingleIcon className="large-icon-size">
            <TrailIcon />
          </SingleIcon>
        )}

        {message && message.type === "resources" && (
          <SingleIcon className="large-icon-size">
            <TrailIcon />
          </SingleIcon>
        )}

        {message && message.type === "authenticationError" && (
          <SingleIcon>
            <CrossBonesIcon />
          </SingleIcon>
        )}

        {message && message.type === "incompleteTresureChest" && (
          <SingleIcon>
            <LockIcon />
          </SingleIcon>
        )}

        {message && message.type === "completeTreasureChest" && (
          <TreasureChestContent>
            <img src={message.treasureChest.image} alt="Prize" />

            <TreasureChestTextContent>
              {message.heading && <Heading>{message.heading}</Heading>}
              {message.paragraph && <Paragraph>{message.paragraph}</Paragraph>}

              {application.user.allAdobeFieldsFilled === false && (
                <ProfilePrompt>
                  <Paragraph>
                    Haven't completed your player profile yet? Click{" "}
                    <a
                      href="/player-profile"
                      onClick={(event) => onOpenPlayerProfileClick(event)}
                    >
                      here
                    </a>{" "}
                    to fill out the form so we can make sure your prize gets to
                    you safely!
                  </Paragraph>
                </ProfilePrompt>
              )}

              {message.button && (
                <ButtonBlock onClick={onNavigateToDifferentCourseClick}>
                  <ButtonInline>
                    <span>{message.button.label}</span>
                    <RightArrowIcon />
                  </ButtonInline>
                </ButtonBlock>
              )}

              <CloseButton
                onClick={() => {
                  dispatch(closeNotificationAsync());

                  if (application.map.isZoomedIn) {
                    dispatch(zoomOutAsync());
                  }

                  setTimeout(() => {
                    dispatch(clearHasCompletedCourseAsync());
                  }, 1000);
                }}
              >
                <NotificationCloseIcon />
              </CloseButton>
            </TreasureChestTextContent>
          </TreasureChestContent>
        )}

        {message && message.type === "profileComplete" && (
          <>
            <Heading>Player Profile Complete</Heading>
            <Paragraph>
              Thank you for registering for EPYC Explorer! We have all of the
              information we need to deliver your prizes to you if you are
              daring enough to complete the platform! Feel free to view your
              completed player profile{" "}
              <a
                href="/player-profile"
                onClick={(event) => onOpenPlayerProfileClick(event)}
              >
                here
              </a>
              . If you have any questions or need to make changes to your
              personal information, please contact us at{" "}
              <a href="mailto:gcp@amd.com">gcp@amd.com</a>. Please refer to our{" "}
              <a href="/privacy-policy">privacy policy</a> for more information.
            </Paragraph>
            <CloseButton
              onClick={() => {
                dispatch(closeNotificationAsync());

                if (application.map.isZoomedIn) {
                  dispatch(zoomOutAsync());
                }

                setTimeout(() => {
                  dispatch(clearHasCompletedCourseAsync());
                }, 1000);
              }}
            >
              <NotificationCloseIcon />
            </CloseButton>
          </>
        )}

        {message && message.type === "completeOutroNoRemainingContent" && (
          <React.Fragment>
            <AnimatedCastle background={true} isNotification={true} />
            {message.heading && <Heading>{message.heading}</Heading>}
            <Paragraph>
              From the mysterious marshes, to the soaring alps, to the
              blistering desert, you've traveled far and overcome many
              challenges during your sales journey. You've conquered each and
              have been awarded with all three available prizes!
              <br />
              <br />
              But guess what? We're not done yet. Tag your relevant GCE/GKE
              opportunities with #AMDEPYCExplorer to be entered into a raffle
              for a Lululemon duffle bag! Each tag will equal 1 entry into the
              raffle. After all, you can never have enough storage for your
              journeys.
              {application.user.allAdobeFieldsFilled === false && (
                <React.Fragment>
                  <br />
                  <br />
                  Haven't completed your player profile yet? Click{" "}
                  <a
                    href="/player-profile"
                    onClick={(event) => onOpenPlayerProfileClick(event)}
                  >
                    here
                  </a>{" "}
                  to fill out the form so we can make sure your prize gets to
                  you safely!
                </React.Fragment>
              )}
            </Paragraph>
            <CloseButton
              onClick={() => {
                dispatch(closeNotificationAsync());
                dispatch(zoomOutAsync());

                setTimeout(() => {
                  dispatch(clearHasCompletedCourseAsync());
                }, 1000);
              }}
            >
              <NotificationCloseIcon />
            </CloseButton>
          </React.Fragment>
        )}

        {message &&
          message.type !== "completeTreasureChest" &&
          message.type !== "completeOutroNoRemainingContent" &&
          message.type !== "profileComplete" && (
            <React.Fragment>
              {message.heading && <Heading>{message.heading}</Heading>}
              {message.subHeading && (
                <SubHeading>{message.subHeading}</SubHeading>
              )}
              {message.paragraph && (
                <Paragraph>{parse(message.paragraph)}</Paragraph>
              )}

              {message.button && message.button.label && (
                <React.Fragment>
                  {message.button.actionType ===
                    "navigateToDifferentCourse" && (
                    <ButtonBlock onClick={onNavigateToDifferentCourseClick}>
                      <ButtonInline>
                        <span>{message.button.label}</span>
                        {message &&
                          message.type !== "incompletePrerequisite" && (
                            <RightArrowIcon />
                          )}
                      </ButtonInline>
                    </ButtonBlock>
                  )}

                  {message.button.actionType === "openCourseInSidebar" && (
                    <ButtonBlock onClick={onOpenCourseInSidebarClick}>
                      <ButtonInline>
                        <span>{message.button.label}</span>
                        <RightArrowIcon />
                      </ButtonInline>
                    </ButtonBlock>
                  )}

                  {message.button.actionType === "navigateToExternalUrl" && (
                    <ButtonBlock onClick={onNavigateToExternalUrlClick}>
                      <ButtonInline>
                        <span>{message.button.label}</span>
                        <RightArrowIcon />
                      </ButtonInline>
                    </ButtonBlock>
                  )}

                  {message.button.actionType === "closeNotification" && (
                    <ButtonBlock onClick={onCloseNotificationClick}>
                      <ButtonInline>
                        <span>{message.button.label}</span>
                        <RightArrowIcon />
                      </ButtonInline>
                    </ButtonBlock>
                  )}

                  {message.button.actionType === "closeFormSuccess" && (
                    <ButtonBlock onClick={onCloseFormSuccess}>
                      <ButtonInline>
                        <span>{message.button.label}</span>
                        {/* <RightArrowIcon /> */}
                      </ButtonInline>
                    </ButtonBlock>
                  )}
                </React.Fragment>
              )}

              {message && message.type !== "authenticationError" && (
                <CloseButton
                  onClick={() => {
                    dispatch(closeNotificationAsync());

                    const messageTypesToZoomOut = [
                      "completeOutroNoRemainingContent",
                      "completeOutroWithRemainingContent",
                    ];

                    if (application.map.isZoomedIn) {
                      if (messageTypesToZoomOut.includes(message.type)) {
                        dispatch(zoomOutAsync());
                      }
                    }

                    setTimeout(() => {
                      dispatch(clearHasCompletedCourseAsync());
                    }, 1000);
                  }}
                >
                  <NotificationCloseIcon />
                </CloseButton>
              )}
            </React.Fragment>
          )}
      </Content>
    </Container>
  );
}

export const ButtonBlock = styled.div`
  background-color: var(--gold-color);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem;
  min-width: 60%;
`;

export const ButtonInline = styled.div`
  display: inline-block;
  border: solid 1px var(--dark-brown-color);
  text-transform: uppercase;
  font-weight: 600;
  padding: 18px 24px;
  width: 100%;

  span {
    color: var(--dark-brown-color);
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
  }

  svg {
    width: 20px;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;

const ProfilePrompt = styled.div`
  p {
    font-weight: 500;

    a {
      font-weight: 500;
    }
  }
`;

const Container = styled.div`
  position: absolute;
  top: 150vh;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  will-change: top;
  transition: top 0.4s ease-in-out, opacity 0.4s linear;
  opacity: 0.75;
  z-index: 50;
  overflow: hidden;  

  &.is-visible-true {
    top: 0;
    opacity: 1;
    transition: top 0.4s ease-in-out, opacity 0.4s linear;

    ${AnimatedChest} {
      animation-duration: 4s;
      animation-name: ${chestOpenAnimation};
    }
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

const Content = styled.div`
  width: 45%;
  max-width: 520px;
  min-width: 200px;
  background-image: url(${paperTexture});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 40px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: left 1s ease;
  min-height: 100px;
  top: 50%;
  left: 50%;
  padding: 55px 55px 40px;
  text-align: center;

  &.is-treasure-chest-true {
    width: 50%;
    max-width: 700px;
    min-width: 400px;
  }

  &.is-complete-no-remaining-content-true {
    background-size: 140%;
    width: 50%;
    max-width: 600px;
    min-width: 400px;
  }

  @media screen and (max-width: 768px) {
    background-size: cover;
  }
`;

const Heading = styled.h3`
  color: var(--dark-brown-color);
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: 21px;
  margin-bottom: 0.5rem;
`;

const SubHeading = styled.h4`
  max-width: 272px;
  margin: 0 auto;
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
`;

const Paragraph = styled.p`
  color: var(--dark-brown-color);
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  a {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
`;

const SingleIcon = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  background-image: url(${paperTexture});
  background-size: 470px;
  background-position: center;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  svg {
    width: 45px;

    path {
      fill: var(--dark-blue-color);
    }
  }

  &.large-icon-size {
    svg {
      width: 68px;
    }
  }
`;

const LargeIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const LargeIconBackground = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 50%;
  opacity: 0.5;
`;

const TreasureChestContent = styled.div`
  display: flex;
  text-align: left;
  align-items: flex-start;

  img {
    max-width: 200px;
    width: 100%;
    height: auto; 
  }

  ${ButtonInline} {
    text-align: center;
    min-width: 235px;
  }
`;

const TreasureChestTextContent = styled.div`
  margin-left: 2rem;

  ${Heading} {
    text-transform: uppercase;
  }

  ${Paragraph} {
    font-family: var(--secondary-font);
    font-size: 15px;
    line-height: 24px;
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 28px;
`;
