import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Auth0Provider } from '@auth0/auth0-react';
import { store } from "./redux/store";

import './index.css';

const container = document.getElementById("root");
const root = createRoot(container);
const YOUR_AUTH0_CLIENT_ID = process.env.CLIENT_ID;
const YOUR_AUTH0_DOMAIN = 'https://epycexplorer.us.auth0.com';

root.render(
  <Auth0Provider
    domain={YOUR_AUTH0_DOMAIN}
    clientId={YOUR_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
