import React from "react";

export default function ClockIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6988 3.69596C19.2054 5.08526 19.4544 7.49527 19.6487 9.97243C19.4103 12.5324 19.6832 15.2328 17.1663 16.6872C16.3463 17.161 14.6354 18.1429 13.7025 18.4635C12.3548 18.9266 11.0807 18.6692 9.65379 18.7811C8.45298 18.8753 7.51535 18.5389 6.37962 18.2483C5.13519 17.9298 4.14292 16.5842 3.2911 15.7547C0.903158 13.4295 1.57776 8.97956 3.32708 6.43023C4.94316 4.07506 7.59556 1.33273 10.8338 1.74458C11.3865 1.81488 11.8571 1.77669 12.4039 1.9105C12.9074 2.0337 13.3979 2.18565 13.898 2.32917C14.9555 2.63268 15.7523 3.17132 16.6988 3.69596Z"
        stroke="#4F453C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.1808 5.5C10.1808 6.62644 9.87404 10.5261 10.1807 11.6059C10.3856 12.3277 13.0293 11.0525 13.5 10.5"
        stroke="#4F453C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
